import React from "react";
import { Controller,useForm } from "react-hook-form";
import List from "../../listofStates";
import Countries from "../../ListofCountries";
import { Link, useLocation, useParams } from "react-router-dom";
import { button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import {formatSentence, base_url, random_number,default_country_code,default_dial_code,api_error_message } from "../../utilities";
import { userProfileValidations } from "../../formValidationsRules";
import moment from "moment";
import Select from "react-select";
import axios from "../../axios-interceptor";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import {YearCompany,Industries,InfluencerList,LearnProgramList} from '../../ObjectiveData'
import PhoneInput from 'react-phone-input-2'

import 'react-phone-input-2/lib/style.css'
///const Salutions = [];
//const allGenders=["Mr.", "Miss.", "Mrs.", "Other"]
const colCls = "col-lg-6 col-md-6 col-sm-12 my_custom_col";
const rowcolCls = "col-lg-3 col-md-3 col-sm-12 my_custom_col";
const listofCountries = function (countries) {
  return (
    <option key={countries.country} value={countries.country}>
      {countries.country}
    </option>
  );
};

const listofitems = function (states) {
  return <option key={states}>{states}</option>;
};
const UserProfile = (props) => {
  const currentDate = moment();
  const last100YearsAgo = moment(currentDate).subtract(100, 'years');
  // Format the dates as strings
   const currentDateString = currentDate.format('YYYY-MM-DD');
   const last100YearsAgoDateString = last100YearsAgo.format('YYYY-MM-DD');
  let [countryOptions, setCountryOptions] = useState([]);
  let [stateOptions, setStateOptions] = useState([]);
  let [cityOptions, setCityOptions] = useState([]);
  // let [disabledInput, setDisabledInput] = useState(false);
  
  const [selectedCountryOption, setSelectedCountryOption] = useState({});
  const [selectedStateOption, setSelectedStateOption] = useState({});
  const [selectedCityOption, setSelectedCityOption] = useState({});
  const currentYear = new Date().getFullYear();
  let [isDisabledButton, setIsDisabledButton] = useState(false);


   let [updated_name,setUpdatedName] = useState('')
  // const courseId = localStorage.getItem("courseIdNew");
  // let cohortId;
  // if (localStorage.getItem("cohortIdNew")) {
  //   cohortId = localStorage.getItem("cohortIdNew");
  // }

  // let learningTrackId;
  // let programId;
  // if (localStorage.getItem("learningTrackId")) {
  //   learningTrackId = localStorage.getItem("learningTrackId");
  // }
  // if (localStorage.getItem("programId")) {
  //   programId = localStorage.getItem("programId");
  // }

  useEffect(() => {
    let profileFilled=localStorage.getItem('profile_filled')
    if(profileFilled==0)
    {
      toast.warning("Please complete the profile before beginning the course.",{toastId:random_number})
    }
    setCountryOptions(
      Countries.map((item,index) => ({ value: index, label: item.country }))
    );
   populateUserData(props?.userData)
    
  }, [])

  const populateUserData=(userData)=>
  {
    if (userData && userData[0]) {
    setUser_Details(userData[0])

    let userDetails=userData
    if(userDetails.length>0)
    {
      setUserData({ ...userData, ['country']: userDetails[0]?.country });
      setUserData({ ...userData, ['state']: userDetails[0]?.state });
      setUserData({ ...userData, ['gender']: userDetails[0]?.gender });
      setUserData({ ...userData, ['pronoun']: userDetails[0]?.pronoun });
  
      setValue('street_address_1',userDetails[0]?.street_address_1, { shouldDirty: true })
      setValue('street_address_2',userDetails[0]?.street_address_2, { shouldDirty: true })
      setValue('dob',userDetails[0]?.dob, { shouldDirty: true })
      setDateOfBirth(userDetails[0]?.dob)
      setValue('year_graduation',userDetails[0]?.graduation_year, { shouldDirty: true })
      setValue('high_education',userDetails[0]?.high_education, { shouldDirty: true })
      setValue('institute_high',userDetails[0]?.high_institute, { shouldDirty: true })
      setValue('postcode',userDetails[0]?.postcode, { shouldDirty: true })
     
      handleCountryPopulate(userDetails[0])
    
     
      setValue('gender',userDetails[0]?.gender, { shouldDirty: true })
      setValue('pronoun',userDetails[0]?.pronoun, { shouldDirty: true })
  
      setValue('Influencer',userDetails[0]?.influencer, { shouldDirty: true })
      setValue('LearnProgram',userDetails[0]?.learn_program, { shouldDirty: true })
      setValue('ParticipantName',userDetails[0]?.participant_name, { shouldDirty: true })
      setValue('programmeYear',userDetails[0]?.program_year, { shouldDirty: true })
      setValue('description',userDetails[0]?.description, { shouldDirty: true })
      
       if(userDetails[0]?.description) setDescriptionValue(userDetails[0]?.description)
  
       let user_country_code = userDetails[0].country_code ? userDetails[0].country_code.startsWith("+") ? userDetails[0].country_code.replace('+', '').trim():userDetails[0].country_code : dialCode;
  
       setPhone(userDetails[0].mobile ? (user_country_code) + userDetails[0].mobile: (user_country_code) +userDetails[0].mobile, { shouldDirty: true });
  
       setDialCode(user_country_code);
  
  
      setPronounList(userDetails[0]?.gender,userDetails[0]?.pronoun)
    }
   }
  }


  const handleCountryPopulate=(userDetails)=>{
    setValue('country',userDetails[0]?.country, { shouldDirty: true })
    setValue('state',userDetails[0]?.state, { shouldDirty: true })
    setValue('city',userDetails[0]?.city, { shouldDirty: true })
    let country= userDetails.country??"India"
    let state=userDetails.state??"Telangana"
    let City=userDetails?.city??"Hyderabad"
    Countries.map((item,index)=>{
      if(item.country===country)
      {
        setSelectedCountryOption({value:index,label:item.country})
        setValue('country', country,{ shouldDirty: true });
     
              setStateOption(item.states);
          // setStateOptions(item.states.map((stateitem,index2) => ({ value: index2, label: stateitem })));
          
        //state
        item.states.map((stateitem2,index3) =>{
         // console.log("state",state);
          if(stateitem2===state)
          {

           // console.log("stateitem",stateitem2);
            //setSelectedStateOption({value:index3,label:stateitem2})
            setSelectedStateOption(stateitem2)
            setValue("state", stateitem2,{ shouldDirty: true });
             
                  //city
                  if(City&&country=="India")
                  {


                  
                    if(item.cities)
                    {
                      for (const key in item.cities) {
                        if (Object.hasOwnProperty.call(item.cities, key)) {
                          const citiArray = item.cities[key];
            
                          if(key===state)
                          {
                            setCityOptions(
                              citiArray.map((city,index)=>({value: index, label: city}))
                              )
  
                              citiArray.map((citiItem,citiindex)=>{
                                if(citiItem===City)
                                {
                                   setSelectedCityOption(citiItem);
                                   setValue('city',citiItem, { shouldDirty: true })
                                   setValue('postalcity',citiItem, { shouldDirty: true })
                                 // console.log("citiItem",citiItem);
                                  //handleCityChange({value:citiindex,label:citiItem})
                                }
                              })
            
                          }
                          
                        }
                      }
                    
                    }

                  }
                  else{
                  
                    setValue('city',City, { shouldDirty: true })
                  } 
          }
        })
      
       }
    })
}

  const setPronounList=(value,pronoun)=>{
        setSalutions(["He/Him","He/They",'She/Her','She/They',"They/Them"]);
        if(pronoun)
        {
        setPronounOption(pronoun);
        setValue("pronoun", pronoun, { shouldValidate: true });
        }
      }
  // const {courseId,cohortId}=props.match.params
  // { user, signOut }
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    
    formState: { errors },
  } = useForm({ mode: "all" });
  const [isAlumniVerified, setIsAlumniVerified] = useState()
  // console.log(Auth.user?.attributes.sub)

  // if(!localStorage.getItem('login')){
  //   setUrl();
  //   loginWithRedirect()
  // }
  let program_value = "";
  let [stateOption, setStateOption] = useState([]);
  let [Salutions, setSalutions] = useState([]);
  let [cohortScheduleList, setcohortScheduleList] = useState([]);
  let [programsList, SetProgramsList] = useState([]);
  let [selectedProgram, setselectedProgram] = useState("");
  let [programeOption, setProgrameOption] = useState(null);
  let [pronounOption, setPronounOption] = useState("");
  let [loading_flag, setLoading_flag] = useState(false);
  let [firstName, setFirstname] = useState("");
  let [email, setEmail] = useState("");
  let [totalYearsErr,setTotalYearsErr]=useState(false);
  let [billingChecked, setBillingChecked] = useState(false);
  let [dateOfBirth, setDateOfBirth] = useState("");
  let [user_Details,setUser_Details]=useState(null)
  const today = new Date().toISOString().slice(0, 10);
  const [phone, setPhone] = useState("");
  const [countryCode, setCountryCode] = useState(default_country_code);
 const [dialCode, setDialCode] = useState(default_dial_code);
  const [mobileErr, setMobileErr] = useState(false);
  let [wordCount, setWordCount] = useState(200);
  let [descriptionValue, setDescriptionValue] = useState("");



  let [verizon_student,setVerizonStudent]= useState("")
  let [userData, setUserData] = useState({
    id: localStorage.getItem("user_id"),
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    dob: "",
    gender: "",
    pronoun: "",
    state: "Telangana",
    country: "India",
    postcode: "",
    city: "Hyderabad",
    role: "",
    company: "",
    industry: "",
    years_company: "",
    total_years: "",
    high_education: "",
    year_graduation: "",
    institute_high: "",
    prev_education: "",
    institute_prev: "",
    cohort: "",
    is_verizon_student:'',
  });
  const [formData, setFormData] = useState({
    id: localStorage.getItem("user_id"),
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    dob: "",
    gender: "",
    pronoun: "",
    state: "Telangana",
    country: "India",
    postcode: "",
    city: "Hyderabad",
    role: "",
    company: "",
    industry: "",
    years_company: "",
    total_years: "",
    high_education: "",
    year_graduation: "",
    institute_high: "",
    prev_education: "",
    institute_prev: "",
    cohort: "",
  });
  const [prevFormData, setPrevFormData] = useState({
    id: localStorage.getItem("user_id"),
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    dob: "",
    gender: "",
    pronoun: "",
    state: "Telangana",
    country: "India",
    postcode: "",
    city: "Hyderabad",
    role: "",
    company: "",
    industry: "",
    years_company: "",
    total_years: "",
    high_education: "",
    year_graduation: "",
    institute_high: "",
    prev_education: "",
    institute_prev: "",
    cohort: "",
  });
  const [isFormChanged, setIsFormChanged] = useState(false);

  // if (userData?.dob?.slice(0, 4) > today?.slice(0, 4)) {
  //   console.log("called")
  //   toast.warning("Please check date of birth",{toastId:random_number});
  // }

  useEffect(() => {
    
    let prefilName = localStorage.getItem("leadform_name");
    let prefilEmail = localStorage.getItem("leadform_email");
    let prefilMobile = localStorage.getItem("leadform_mobile");

    if (prefilName) {
      setValue("first_name", updated_name??prefilName, { shouldDirty: true });
      setFirstname(updated_name??prefilName);
    }
    if (prefilEmail) {
      setValue("email", prefilEmail, { shouldDirty: true });
      setEmail(prefilEmail);
    }
    if (prefilMobile) {
      setValue("mobile", prefilMobile, { shouldDirty: true });
    }
    if (localStorage.getItem("user_id")) {
      
      axios.post(base_url+'isb/user/get_user_details',{"user_id": localStorage.getItem('user_id')})
        .then((res) => {
          if(res.data?.data?.profile_filled==0)
          {
            toast.warning("Please complete the profile before beginning the course.",{toastId:random_number})
          }
          let result = res.data.result[0];
          setIsAlumniVerified(result.is_verified_alumni)
          setValue("first_name", result.first_name, { shouldDirty: true });
          setFirstname(result.firstName);
          setValue("last_name", result.last_name, { shouldDirty: true });
          setValue("email", result.email, { shouldDirty: true });
          setEmail(result.email);
          setValue("mobile", result.mobile, { shouldDirty: true });
          setValue("role", result.role, { shouldDirty: true });
          setValue("total_years", result.total_years, { shouldDirty: true });
          setValue("years_company", result.years_company, {
            shouldDirty: true,
          });
          if(result.state!==""&&result.state)
          {
            setValue("state", result.state, {
              shouldDirty: true,
            });
          }
          if(result.city!==""&&result.city){
            setValue("postalcity", result.city, {
              shouldDirty: true,
            });
          }
          
          setValue("industry", result.industry, { shouldDirty: true });
          setValue("company", result.company, { shouldDirty: true });
          const initailValues = JSON.parse(JSON.stringify(getValues()))
          initailValues['id']=localStorage.getItem("user_id")
          initailValues['mobile']=result.country_code+""+result.mobile
          initailValues['country_code']=(initailValues.country_code ? (initailValues.country_code.startsWith("+") ? initailValues.country_code.replace('+', '').trim():initailValues.country_code) : dialCode)
          setPrevFormData(initailValues);
          setFormData(initailValues)
          setLoading_flag(true); 
        })
       
         
      
        .catch((err) => {
          console.log(err);
          if(err.response?.statusText=='Unauthorized'||err.response?.data?.statusText=='Unauthorized'){
            localStorage.clear();
            history.push('/')
            window.location.reload();
          }else{
            toast.warn(api_error_message,{toastId:random_number});
          }
        });
    }

  },  []);
  let [formErrors, setFormErrors] = useState({});
  let history = useHistory();
  let location = useLocation();
  // let cohort_details = location.state.cohort
  const onSubmit = (data) => {
   
    let mobile;
    if(phone == dialCode){
      setMobileErr(true);
      window.scrollTo({'top':50,behavior:'smooth'})
      return false;
    }
    if(phone){
      mobile = phone.substring(dialCode.length);
      if(mobile.toString().length < 4 || mobile.toString().length > 13){
        setMobileErr(true);
        window.scrollTo({'top':50,behavior:'smooth'})
        return false;
      }else{
        setMobileErr(false);
      }
    }else{
      setMobileErr(true);
      window.scrollTo({'top':50,behavior:'smooth'})
      return false;
    }
    const { dob, city, postalcity,company, country, email, first_name, gender, high_education, industry, institute_high, last_name,postcode, pronoun, role, state, street_address_1, street_address_2, total_years, year_graduation, years_company,Influencer,LearnProgram,ParticipantName,programmeYear,UserConsent1,UserConsent2,payeesFirstName,payeesEmail,payeesMobile,PanId } = data;
    const saveData = {
      is_verified_alumni : isAlumniVerified ? true : false,
      dob: dob ?? null,
      city : selectedCountryOption.label=="India"?postalcity:city,
      company: company ? company.trim().replace(/\s+/g, ' '): null,
      country: country ?? null,
      email: email ?? null,
      first_name:first_name? formatSentence(first_name.trim().replace(/\s+/g, ' ')): null,
      gender: gender ?? null,
      high_education: high_education ? high_education.trim().replace(/\s+/g, ' '):null,
      industry: industry ?? null,
      high_institute: institute_high ?institute_high.trim().replace(/\s+/g, ' '): null,
      last_name: last_name ?? null,
      mobile: mobile ?? null,
      postcode: postcode ?? null,
      pronoun: pronoun ?? null,
      role: role ? role.trim().replace(/\s+/g, ' '):null,
      state: state ?? null,
      street_address_1: street_address_1 ? street_address_1.trim().replace(/\s+/g, ' '): null,
      street_address_2: street_address_2 ?street_address_2.trim().replace(/\s+/g, ' '): null,
      total_years: total_years ?? null,
      graduation_year: year_graduation ?? null,
      years_company: years_company ?? null,
      influencer:Influencer,
      learn_program:LearnProgram,
      participant_name:ParticipantName,
      program_year:programmeYear,
      description:descriptionValue,
      country_code:dialCode,
      
    };
   if(!totalYearsErr){
    document.getElementById('profile_update_submit_btn').innerHTML = 'Wait...';
    axios.post(base_url+'isb/user/update_user_details',{"id":localStorage.getItem('user_id'),
    "user_details":saveData
  })
    .then(res=>{
      function capitalizeFirstLetterOfEveryWord(sentence) {
        const words = sentence.split(" ");        
        const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
        return capitalizedWords.join(" ");
      }
      const message = res.data?.message;
      const capitalizedMessage = capitalizeFirstLetterOfEveryWord(message);
      document.getElementById('profile_update_submit_btn').innerHTML = 'Submit';
      toast.success(capitalizedMessage, { id: random_number });
      // let profileFilled=localStorage.getItem('profile_filled')
      // if(profileFilled==0)
      // {
        // localStorage.removeItem('profile_filled')
        history.push('/dashboard')
      // }
    })
    .catch(err=>{
      console.log(err);
      if(err.response?.statusText=='Unauthorized'||err.response?.data?.statusText=='Unauthorized'){
        localStorage.clear();
        history.push('/')
        window.location.reload();
      }else{
        toast.warn(api_error_message,{toastId:random_number});
      }
    })
  }else{
    window.scrollTo({top:300,behavior:'smooth'})
  }
  };
  const handleOnChange = (value, country) => {
    setPhone(value.toString());
    setDialCode(country.dialCode)
    setFormData((prevData) => ({ ...prevData, ['mobile']: value}));
  }

  const validateFields=(name,value)=>{
    if (name === "institute_high" && value.length ===200) {
      errors[name] = {
        type: "maxLength",
        message: "Sorry, you have reached the character limit (200 characters max).",
      };
    }
    else if (name === "high_education" && value.length ===100) {
      errors[name] = {
        type: "maxLength",
        message: "Sorry, you have reached the character limit (100 characters max).",
      };
    }
    else if (name === "street_address_2" && value.length ===200) {
      errors[name] = {
        type: "maxLength",
        message: "Sorry, you have reached the character limit (200 characters max).",
      };
    }
    else if (name === "street_address_1" && value.length ===200) {
      errors[name] = {
        type: "maxLength",
        message: "Sorry, you have reached the character limit (200 characters max).",
      };
    }
    else if (name === "company" && value.length ===60) {
      errors[name] = {
        type: "maxLength",
        message: "Sorry, you have reached the character limit (60 characters max).",
      };
    }
    else if (name === "role" && value.length ===60) {
      errors[name] = {
        type: "maxLength",
        message: "Sorry, you have reached the character limit (60 characters max).",
      };
    }
    else{
      errors[name] = {
        type: "maxLength",
        message: "",
      };
    }

  }
  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value1 = e.target.value
    const value = value1.trim();

    validateFields(name,value);
  
    if (name === "postcode" ||name == "mobile") {
      setValue(e.target.name, e.target.value.split(" ").join(""), {
        shouldValidate: true,
      });
    }
    if(name==='years_company')
    {
      const {total_years}=getValues()
      if(parseInt(target.value)>parseInt(total_years))
      {
        setTotalYearsErr(true)
      }
      else{
        setTotalYearsErr(false)
      }
    }else if(name==='total_years'){
      const {years_company}=getValues()
      if(parseInt(years_company)>parseInt(target.value))
      {
        setTotalYearsErr(true)
      }
      else{
        setTotalYearsErr(false)
      }
    }

    if (name == "country") {
      Countries.map((cntry) => {
        if (cntry.country == value) {
          setStateOption(cntry.states);
        }
      });
    }

    if (name == "pronoun") {
        setPronounOption(value);
        setValue("pronoun", value, { shouldDirty: true });
    }
    // if (name == "program") {
    //   if (value !== "") {
    //     programsList.map((obj) => {
    //       if (value == obj._id && localStorage.getItem("user_id")) {
    //         setProgrameOption(obj);
    //         program_value = value;
    //         setselectedProgram(value);
    //        // checkValuesAvailable();
    //       }
    //     });
    //     setValue("program", value, { shouldDirty: true });
    //   }
    // }
    if (name == "industry" || name == "company" || name == "role") {
      const pattern = /^[A-Za-z]+$/;
      if (value === "" || pattern.test(value)) {
        setUserData({ ...userData, [name]: value });
      }
    }
    if (name == "dob") {
      setDateOfBirth(value)
      setUserData({ ...userData, [name]: value });
    }
    // if (name == "last_name") {
    //   setUserData({ ...userData, [name]: value });
    // }
    if (name == "first_name") {
      setFirstname(value);
    }
    if (name == "email") {
      setEmail(value);
    }
    // if( name == "verizon_student"){
    //   setValue("first_name", value, { shouldDirty: true });
    //   // setFirstname(value);
    //   setUpdatedName(value)
    // }

      if (name == 'country') {
          Countries.map((cntry,index)=> {
            if (cntry.country == value) {
              setSelectedCountryOption({value:index,label:value})
             // setSelectedCountryOption(value)
              setStateOption(cntry.states)
               //setSelectedStateOption(cntry.states[0])
            }
            
          })
          setSelectedCityOption({});
          setValue('city','', { shouldDirty: true });
          setValue('state','', { shouldDirty: true });
          setUserData({ ...userData, [name]: value });
        }
        
        if (name == 'state') {
          setSelectedStateOption(value);
          setValue('state',value, { shouldDirty: true });
          setUserData({ ...userData, [name]: value });
        
            Countries.map((cntry) => {
              if (cntry.states.includes(value)) {
                setCityOptions(
                  cntry.cities[value].map((city,index)=>({value: index, label: city}))
                  )


              }

              
            });
        }
        if (name == 'postalcity') {

          setSelectedCityOption(value);
          setValue('city',value, { shouldDirty: true });
          setUserData({ ...userData, [name]: value });


        }

       

       
        
        setFormData((prevData) => ({ ...prevData, [name]: (name=='years_company'||name=='total_years'||name=='year_graduation')? +value :value }));
        
       
  };
  //console.log('city',cityOptions)

  useEffect(()=>{
    if (JSON.stringify(formData) !== JSON.stringify(prevFormData)) {
      setIsFormChanged(true);
    } else {
      setIsFormChanged(false);
    }
  },[formData])


  // const checkValuesAvailable = () => {
  //   if (email !== "" && firstName !== "" && userData.last_name !== "") {
  //     setShow_next("show");
  //   } else {
  //     setShow_next("");
  //   }
  // };



const validatePostCode = (value) => {
  if(value.length < 3 || value.length > 8) {
   return "Postal Code must contain between 3 and 8 digits";
 }
   if(selectedCountryOption.label=="Argentina" || selectedCountryOption.label=="Brunei" || selectedCountryOption.label=="Canada" || selectedCountryOption.label=="Ireland" || selectedCountryOption.label=="Kazakhstan" || selectedCountryOption.label=="Malta" || selectedCountryOption.label=="Netherlands"|| selectedCountryOption.label=="Peru" || selectedCountryOption.label=="Somalia" || selectedCountryOption.label=="United Kingdom" || selectedCountryOption.label=="Swaziland"){
    if (!/^\w+$/.test(value)) {
      return "Please enter a valid Postal Code";
    }
   }
   else{
    if (!/^\d+$/.test(value)) {
      return "Postal Code should contain only numbers";
    }
  }
  return true;
};

const validateAge = (dob) => {
  const today = new Date();
    const birthDate = new Date(dob);

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      return age - 1 >= 16 || "Age must be at least 16 years";
    }

    return age >= 16 || "Age must be at least 16 years";
};





  return (
    <>
      {!loading_flag ? (
     <div>
     <img data-testid="atrium_loader" className="atrium_loader" src="images/atrium_loader.gif" alt="Loader" />
   </div>
      ) : (
        <>
          {/* <LandNavbar/> */}
          <div className="container container-onlineApp mt-5">
            <div className="online_applictn_form_p1">
              <div className="online_application_heading mb-2">
                My Profile
              </div>
              <div className="edit-container">
                <span className={`${window.innerWidth > 993 ? 'online_application_main_des' : 'address-subhead'} d-inline-block`}>
                You can edit your profile on this page. Click Submit at the bottom of the page when you are done
                </span>
                {/* <i className={`fas fa-edit edit-icon ${isDisabledButton ? '' : 'black'}`} onClick={handleEditClick}></i> */}
              </div>
            </div>
          </div>
          <div className={`${window.innerWidth>993?'mt-5':'mt-3'} container container-onlineApp`}>
            <div className="containe form_field_container_cls_online_applictn">
              <form autoComplete="false">
                <div className="row form_field_cls">
                  <h3 className="online_app_main_head mb-4">What name would you like to be printed on the certificate? </h3>
                    <div className="col-12">
                      <label htmlFor="exampleInput" className="InputLabel">Name on Certificate <span className="requiredInput">*</span></label>
                      <input {...register("first_name",userProfileValidations.first_name )}
                        type="text"
                        className="form-control boder-radius-0"
                        id="first_name"
                        name="first_name"
                        placeholder=" "
                        data-testid="first_name"
                        autoFocus
                        required
                        role="presentation" autoComplete="off"
                        onChange={handleChange}
                        disabled={isDisabledButton}
                       // onKeyUp={checkValuesAvailable}
                        
                      />
                      {errors.first_name?.message && <span style={{ color: "red" }}>{errors.first_name?.message}</span>}
                    </div>
                </div>
              </form>
            </div>
          </div>

          <div className={`${window.innerWidth>993?'mt-5':'mt-3'} container container-onlineApp`}>
            <div className="containe form_field_container_cls_online_applictn">
              <form autoComplete="false">
                <div className="row form_field_cls">
                  <h3 className="online_app_main_head mb-4">
                    How do we get in touch with you?{" "}
                  </h3>
                  {/* <div className="row  form_field_cls"> */}
                  <div className={colCls}>
                    <label htmlFor="exampleInput" className="InputLabel">
                      Email <span className="requiredInput">*</span>
                    </label>
                    <input
                      {...register("email", userProfileValidations.email)}
                      type="email"
                      id="email"
                      readOnly="true"
                      name="email"
                      data-testid="email"
                      onChange={handleChange}
                      //onKeyUp={checkValuesAvailable}
                      className="form-control boder-radius-0"
                      placeholder=""
                      role="presentation"
                      autoComplete="off"
                    />

                    {errors.email?.message && (
                      <span style={{ color: "red" }}>
                        {errors.email?.message}
                      </span>
                    )}
                    {/* {errors.email && <span style={{ color: "red" }}>This field is required</span>} */}
                  </div>
                  <div className={colCls}>
              <label htmlFor="exampleInput" className="InputLabel">Mobile <span className="requiredInput">*</span></label>
                <div data-testid="Phone_input">
                <PhoneInput
                  country={countryCode}
                  value={phone}
                  onChange={handleOnChange}
                  disabled={isDisabledButton}
                  separateDialCode={true} // Display flag and code in one block
                  />
                  </div>
                <span className={`flag-icon flag-icon-${countryCode}`}></span>
                {mobileErr && <span style={{ color: "red" }}>Please enter a valid mobile</span>}
              </div>
                </div>
              </form>
            </div>
          </div>
          {/* =========================SECTION TWO=========================== */}

          { (
            <div className={`${window.innerWidth>993?'mt-5':'mt-3'} container container-onlineApp`}>
              <div className="containe form_field_container_cls_online_applictn">
                <form className="section_two">
                  <h3 className="online_app_main_head">
                    Professional Experience&nbsp;
                    <span className="requiredInput">*</span>{" "}
                  </h3>
                  <p className="online_app_main_head_comnt">
                    Help us understand your professional background.
                  </p>
                    <div className="row form_field_cls mt-4">
                        <div className={colCls}>
                          <label htmlFor="exampleInput" className="InputLabel">Which is your current company? <span className="requiredInput">*</span></label>
                          <input {...register("company", userProfileValidations.company)}
                            id="company"
                            name="company"
                            data-testid="company"
                            className="form-control boder-radius-0"
                            placeholder=" "
                            disabled={isDisabledButton}
                            role="presentation" autoComplete="off"
                            // value={userData.company}
                            onChange={handleChange}
                            maxLength={60}
                            required
                          ></input>
                          {errors.company?.message && <span style={{ color: "red" }}>{errors.company?.message}</span>}

                        </div>
                        <div className={colCls}>
                          <label htmlFor="exampleInput" className="InputLabel">What’s your position in your current company? <span className="requiredInput">*</span></label>
                          <input {...register("role", userProfileValidations.role)}

                          
                            id="role"
                            name="role"
                            data-testid="role"
                            className="form-control boder-radius-0"
                            placeholder=" "
                            disabled={isDisabledButton}
                            role="presentation" autoComplete="off"
                            // value={userData.role}
                            onChange={handleChange}
                            maxLength={60}
                            required
                          />
                          {errors.role?.message && <span style={{ color: "red" }}>{errors.role?.message}</span>}

                        </div>
                        <div className={colCls}>
                          <label htmlFor="exampleInput" className="InputLabel">How many years have you worked there? <span className="requiredInput">*</span></label>
                              <select {...register("years_company", userProfileValidations.years_company)}
                            id="years_company"
                            name="years_company"
                            data-testid="years_company"
                            className="form-select"
                            disabled={isDisabledButton}
                            aria-label="Default select example"
                            role="presentation" autoComplete="off"
                            // value={selectedProgram}
                            required
                            onChange={handleChange}>
                              
                            <option value=''>Select years of experience</option>
                            {YearCompany.map((item,index) => {
                                return <option  key={index} value={item.value} >{item.text}</option>;
                            })}
                          </select>
                          {errors.years_company?.message && <span style={{ color: "red" }}>{errors.years_company?.message}</span>}

                        </div>
                        <div className={colCls}>
                          <label htmlFor="exampleInput" className="InputLabel">What industry is your current company in? <span className="requiredInput">*</span></label>
                  
                            <select {...register("industry", userProfileValidations.industry)}
                            id="industry"
                            name="industry"
                            data-testid="industry"
                            className="form-select"
                            disabled={isDisabledButton}
                            aria-label="Default select example"
                            role="presentation" autoComplete="off"
                            // value={selectedProgram}
                            required
                            onChange={handleChange}>
                            <option value=''>Select Industry</option>
                            {Industries.map((item,index) => {
                                return <option  key={index} value={item} >{item}</option>;
                            })}
                          </select>
                          {errors.industry?.message && <span style={{ color: "red" }}>{errors.industry?.message}</span>}

                        </div>
                        <div className={colCls}>
                          <label htmlFor="exampleInput" className="InputLabel">
                          How many years of total experience do you have? <span className="requiredInput">*</span>
                          </label>
                     
                            <select {...register("total_years", userProfileValidations.total_years)}
                            id="total_years"
                            name="total_years"
                            data-testid="total_years"
                            className="form-select"
                            aria-label="Default select example"
                            role="presentation" autoComplete="off"
                            disabled={isDisabledButton}
                            // value={selectedProgram}
                            required
                            onChange={handleChange}>
                            <option value=''>Select years of experience</option>
                            {YearCompany.map((item,index) => {
                                return <option  key={index} value={item.value} >{item.text}</option>;
                            })}
                          </select>
                          {errors.total_years?.message && <span style={{ color: "red" }}>{errors.total_years?.message}</span>}

                        </div>
                        <div className={colCls}>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=" "
                            style={{ display: "none" }}
                          />
                        </div>
                        <div>
                        {totalYearsErr&& <span data-testid="totalYearsErr" style={{ color: "red" }}>The years you have worked overall should be greater than or equal to the years worked in current company</span>}
                        </div>
                    </div>
                </form>
              </div>
            </div>
          )}

          {/* =============================SECTION TWO.ONE======================================== */}

          


          {/* =======================================SECTION THREE============================================ */}

          <div className={`${window.innerWidth>993?'mt-4':'mt-3'} container container-onlineApp`}>
            <div className="address_applictn_form_p1">
              <div className="address-head-main">
                <h3 className={`${window.innerWidth>993?'':'m-0'} online_app_main_head p-0`}>
                Education <span className="requiredInput">*</span>{" "}
                </h3>
                <span className="address-subhead mt-1">
                Help us understand your educational background.{" "}
                </span>
              </div>

              <div className="row form_field_cls mt-2">
                <div className={colCls}>
                  <label htmlFor="exampleInput" className="InputLabel ">
                  What is your highest academic qualification?{" "} <span className="requiredInput">*</span>
                  </label>
                  <input
                    {...register("high_education", userProfileValidations.high_education)}
                    id="high_education"
                    name="high_education"
                    className="form-control boder-radius-0"
                    placeholder=" "
                    disabled={isDisabledButton}
                    data-testid="high_education"
                    role="presentation"
                    autoComplete="off"
                    // value={userData.company}
                    onChange={handleChange}
                    maxLength={100}
                    required
                  ></input>
                  {errors.high_education?.message && (
                    <span style={{ color: "red" }}>
                      {errors.high_education?.message}
                    </span>
                  )}
                </div>
                <div className={colCls}>
                  <label htmlFor="exampleInput" className="InputLabel">
                  Which institute did you study at?{" "} <span className="requiredInput">*</span>
                  </label>
                  <input
                    {...register("institute_high", userProfileValidations.institute_high)}
                    id="institute_high"
                    name="institute_high"
                    data-testid="institute_high"
                    className="form-control boder-radius-0"
                    placeholder=" "
                    role="presentation"
                    autoComplete="off"
                    // value={userData.role}
                    //disabled={disabledInput}
                    onChange={handleChange}
                    disabled={isDisabledButton}
                    maxLength={200}
                    required
                  />                     
                  {errors.institute_high?.message && (
                    <span style={{ color: "red" }}>{errors.institute_high?.message}</span>
                  )}                
                </div>
                <div className={colCls}>
                  <label htmlFor="exampleInput" className="InputLabel">
                  Which year did you graduate?{" "} <span className="requiredInput">*</span>
                  </label>
                  <input
                    {...register("year_graduation",{
                      ...userProfileValidations.year_graduation
                      ,validate: {
                      notFutureYear: (value) => parseInt(value) <= currentYear || "Year cannot be in the future"
                    }})}
                    id="year_graduation"
                    name="year_graduation"
                    data-testid="year_graduation"
                    type="nubmer"
                    className="form-control boder-radius-0"
                    placeholder=" "
                    role="presentation"
                    autoComplete="off"
                    onChange={handleChange}
                    disabled={isDisabledButton}
                    required
                  ></input>
                  {errors.year_graduation?.message && (
                    <span style={{ color: "red" }}>
                      {errors.year_graduation?.message}
                    </span>
                  )}
                </div>
                <div className={colCls}>
                      <input
                        type="text"
                        className="form-control"
                        placeholder=" "
                        style={{ display: "none" }}
                      />
                    </div>
              </div>
            </div>
          </div>
              {/* =======================================SECTION FOUR============================================ */}


              <div className={`${window.innerWidth>993?'mt-4':'mt-3'} container container-onlineApp`}>
            <div className="address_applictn_form_p1">
              <div className="address-head-main">
                <h3 className={`${window.innerWidth>993?'':'m-0'} online_app_main_head p-0`}>
                Personal{" "} <span className="requiredInput">*</span>
                </h3>
              </div>

              <div className="row form_field_cls mt-2">
                <div className={colCls}>
                  <label htmlFor="exampleInput" className="InputLabel">
                    Date of Birth{" "} <span className="requiredInput">*</span>
                  </label>
                  <input
                        {...register("dob",{...userProfileValidations.dob, validate: { validateAge }})}
                          id="dob"
                          data-testid="dob"
                          name="dob"
                          //className="form-control boder-radius-0"
                          placeholder=" "
                          role="presentation"
                          autoComplete="off"
                          required
                          type="date"
                          value={dateOfBirth} 
                          onChange={handleChange} 
                          disabled={isDisabledButton}

                          className="form-control boder-radius-0"
                          min={last100YearsAgoDateString}
                          max={currentDateString}
                          />  
                  {errors.dob?.message && (
                    <span data-testid="dob_errors" style={{ color: "red" }}>
                      {errors.dob?.message}
                    </span>
                  )}
                </div>
                <div className={rowcolCls}>
                  <label htmlFor="exampleInput" className="InputLabel">
                    Gender{" "} <span className="requiredInput">*</span>
                  </label>
                  <select {...register("gender", userProfileValidations.gender)}
                  id="gender"
                  data-testid="gender"
                  role="presentation" autoComplete="off"
                  name="gender"
                  className="form-select"
                  aria-label="Default select example"
                  onChange={handleChange}
                  disabled={isDisabledButton}
                >
                  <option value="">Select</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </select>
                {errors.gender?.message && <span style={{ color: "red" }}>{errors.gender?.message}</span>}
                </div>
                <div className={rowcolCls}>
                  <label htmlFor="exampleInput" className="InputLabel">
                    Pronoun{" "} <span className="requiredInput">*</span>
                  </label>
                  <select {...register("pronoun",userProfileValidations.pronoun )}
                  id="pronoun"
                  data-testid="pronoun"
                  name="pronoun"
                  className="form-select"
                  aria-label="Default select example"
                  role="presentation" autoComplete="off"
                   value={pronounOption}
                   disabled={isDisabledButton}
                  onChange={handleChange}>
                    
                  <option value="">Select</option>
                  {Salutions.map((s,index) => {
                     return <option  key={index} value={s} >{s}</option>;
                  })}
                </select>
                {errors.pronoun?.message && <span data-testid="pronoun_err" style={{ color: "red" }}>{errors.pronoun?.message}</span>}
                </div>
              </div>
            </div>
          </div>
 
             {/* =======================================SECTION FIVE============================================ */}
             <div className={`${window.innerWidth>993?'mt-4':'mt-3'} container container-onlineApp`}>
            <div className="address_applictn_form_p1">
              <div className="address-head-main">
                <h3 className={`${window.innerWidth>993?'':'m-0'} online_app_main_head p-0`}>
                {"Address for Certificate"}{" "} <span className="requiredInput">*</span>
                </h3>
                <span className="address-subhead mt-1">
                  This address will be used to ship your printed certificate.
                  {" "}
                </span>
              </div>

              <div className="row form_field_cls mt-2">
                <div className={colCls}>
                  <label htmlFor="exampleInput" className="InputLabel">
                    Street Address 1{" "} <span className="requiredInput">*</span>
                  </label>
                  <input
                    {...register("street_address_1", userProfileValidations.street_Address_1)}
                    id="street_address_1"
                    data-testid="street_address_1"
                    name="street_address_1"
                    className="form-control boder-radius-0"
                    placeholder=" "
                    role="presentation"
                    autoComplete="off"
                    // value={userData.company}
                    onChange={handleChange}
                    maxLength={200}
                    disabled={isDisabledButton}
                    required
                  ></input>
                  {errors.street_address_1?.message && (
                    <span style={{ color: "red" }}>
                      {errors.street_address_1?.message}
                    </span>
                  )}
                </div>
                <div className={colCls}>
                  <label htmlFor="exampleInput" className="InputLabel">
                    Street Address 2{" "}
                  </label>
                  <input
                    {...register("street_address_2", userProfileValidations.street_address_2)}
                    id="street_address_2"
                    data-testid="street_address_2"
                    name="street_address_2"
                    className="form-control boder-radius-0"
                    placeholder=" "
                    role="presentation"
                    autoComplete="off"
                    // value={userData.role}
                    onChange={handleChange}
                    maxLength={200}
                    disabled={isDisabledButton}
                    required
                  />
                  {errors.street_address_2?.message && (
                    <span style={{ color: "red" }}>{errors.street_address_2?.message}</span>
                  )}
                </div>
                <div className={colCls}>
                  <label htmlFor="exampleInput" className="InputLabel">Country </label> <span className="requiredInput">*</span>

                  <select
                    {...register("country", userProfileValidations.country)}
                    data-testid="country"
                    className="form-select"
                    aria-label="Default select example"
                    role="presentation"
                    autoComplete="off"
                    value={userData.country}
                    disabled={isDisabledButton}
                    onChange={handleChange}
                    name="country"
                    id="country"
                  >
                    <option value="">Select</option>
                    {Countries.map(listofCountries)}
                  </select>
                  {errors.country?.message && (
                    <span style={{ color: "red" }}>
                      {errors.country?.message}
                    </span>
                  )}

                </div>
                <div className={colCls}>
                  <label htmlFor="exampleInput"   className="InputLabel">State </label> <span className="requiredInput">*</span>
                  <select
                    {...register("state", userProfileValidations.state)}
                    name="state"
                    data-testid="state"
                    id="state"
                    className="form-select"
                    role="presentation"
                    autoComplete="off"
                    aria-label="Default select example"
                    disabled={isDisabledButton}
                    //value={userData.state}
                    value={selectedStateOption}
                   onChange={handleChange}

                  >
                    <option value="">Select</option>
                    {stateOption.map(listofitems)}
                  </select>
                  {errors.state?.message && (
                    <span style={{ color: "red" }}>
                      {errors.state?.message}
                    </span>
                  )}
                    </div>
                <div className={colCls}>
                  <label htmlFor="exampleInput" className="InputLabel">
                    City{" "} <span className="requiredInput">*</span>
                  </label>
                  {selectedCountryOption.label=="India"?
                  <>

                    <select
                    {...register("postalcity", userProfileValidations.postalCity)}
                    name="postalcity"
                  
                    id="postalcity"
                    data-testid="postalCity"
                    className="form-select"
					          value={selectedCityOption}
                    role="presentation"
                    autoComplete="off"
                    aria-label="Default select example"
                    disabled={isDisabledButton}
                    onChange={handleChange}
                  >
                    <option value="">Select</option>
                   {cityOptions.map((item,index)=>{
                     return <option key={index} value={item.label}>{item.label}</option>
                
                    })}
                     {/* {cityOptions.map(listofcities)}  */}
                  </select>
                  
                  {errors.postalcity?.message && (
                    <span style={{ color: "red" }}>
                      {errors.postalcity?.message}
                    </span>
                  )}


                  </>:
                  <>
                      <input
                    {...register("city", userProfileValidations.city)}
                    id="city"
                    name="city"
                    data-testid="city"
                    type="nubmer"
                    className="form-control boder-radius-0"
                    placeholder=" "
                    role="presentation"
                    autoComplete="off"
                    disabled={isDisabledButton}
                    onChange={handleChange}
                    required
                  ></input>
                  {errors.city?.message && (
                    <span style={{ color: "red" }}>
                      {errors.city?.message}
                    </span>
                  )}
                  </>}
                </div>
                <div className={colCls}>
                  <label htmlFor="exampleInput" className="InputLabel">
                    Post Code{" "} <span className="requiredInput">*</span>
                  </label>
                  <input

                    {...register("postcode", { validate: validatePostCode } 
                    )}
                    id="postcode"
                    data-testid="postcode"
                    name="postcode"
                    className="form-control boder-radius-0"
                    placeholder=" "
                    role="presentation"
                    autoComplete="off"
                    // value={userData.industry}
                    onChange={handleChange}
                    disabled={isDisabledButton}
                    required
                  ></input>
                  {errors.postcode?.message && (
                    <span data-testid="postal_error" style={{ color: "red" }}>
                      {errors.postcode?.message}
                    </span>
                  )}
                </div>
            

                <div className={colCls}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=" "
                    style={{ display: "none" }}
                  />
                </div>
              </div>
            </div>
          </div>


            <div className={`${window.innerWidth>993?'mt-4':'mb-5'} goto_part_2_btn d-block text-center`}>
              <button
              data-testid="submit"
                type="button"
                id="profile_update_submit_btn"
                className={`${window.innerWidth>993?'':'px-4'} btn my_btn_cls col-sm-4 col-lg-2 col-md-2 online_app_submit`}
                onClick={handleSubmit(onSubmit)}
                disabled={!isFormChanged}
              >
                Submit
              </button>
            </div>
             {/* for test cases */}
             <span className="d-none" data-testid="phone_set" onClick={(e)=>{setPhone("")}}></span>

            
        </>
      )}
    </>
  );
};
export default UserProfile;
