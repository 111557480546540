import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import axios from "../../axios-interceptor";
import {base_url,date_formats,random_number,api_error_message, admin_maintenance_type} from "../../utilities";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import "react-toastify/dist/ReactToastify.css";
import withMaintenanceCheck from "../../HigherOrderComponents/maintenancePageChecker";
import { Modal } from "react-bootstrap";
import moment from "moment";


const AdminApproval = (props) => {
  const [application, setApplication] = useState([]);
  const [getDetails, setGetDetails] = useState([]);
  const [previousCourse, setPreviousCourse] = useState([]);
  let [showRemoveAccessModal, setRemoveAccessModal] = useState(false);
  let [showRevokeAccessModal, setRevokeAccessModal] = useState(false);
  let [showRetainAccessModal, setRetainAccessModal] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(true);
  const [buttonDisableNext, setButtonDisableNext] = useState(false);
  const [disableAcceptBtn, setDisableAcceptBtn] = useState(false);
  const [dropDownValue, setDropdownValue] = useState(0);
  const [selectedDropDownItem, setSelectedDropDownItem] = useState(null);
  const [status, setStatus] = useState(0);
  const [index, indexStatus] = useState(0);
  const [rej_value, setRejValue] = useState("");
  const [comment_vlaue, setCommentValue] = useState("");
  const [application_rej_reason, setApplication_rej_reason] = useState("");
  const [application_rej_comment, setApplication_rej_comment] = useState("");
  let [loading_flag, Setloading_flag] = useState(true);
  const [clearedDate, setClearedDate] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isRemoveChecked, setIsRemoveChecked] = useState(false);
  const [isExtendDateChecked, setIsExtendDateChecked] = useState(false);
  const [isRevokeChecked, setIsRevokeChecked] = useState(false);
  const [dateoraccessUpdate,setDateoraccessUpdate]=useState(false)
 
  const applicationStatus = [
    { status: "Pending", id: 0 },
    { status: "Rejected", id: 1 },
    { status: "Approved", id: 2 },
    { status: "Banned", id: 3 },
  ];
  const history = useHistory();
  const location = useLocation();
  const approvalsData = location.state;
  const [dropdown, setDropDown] = useState(false);

  if (!localStorage.getItem("Admin_Acesstoken")) {
    history.push("/adminlogin");
  }
  useEffect(() => {
    if (selectedDropDownItem) {
      if(selectedDropDownItem.payment_date)
      {
        setClearedDate(selectedDropDownItem.payment_date);
      }
      if(selectedDropDownItem.is_banned) //temporarily_revoked
      {
        setIsRevokeChecked(true)
      }
      else{
        setIsRevokeChecked(false)
      }
      if(selectedDropDownItem.permanent_revoked)
      {
        setIsRemoveChecked(true)
      }
      else{
        setIsRemoveChecked(false)
      }
     
    }
  }, [selectedDropDownItem]);

  //when click on previous
  var arrayForPreviousApplication = getDetails;
  const PreviousApplication = () => {
    setButtonDisableNext(false);
    arrayForPreviousApplication.map((apptwo, index) => {
      apptwo.color = "#057092";
      apptwo.background = "white";
      if (selectedDropDownItem._id == apptwo._id) {
        //indexStatus(index)
        if (index == 1) {
          setButtonDisable(true);
        }
        if (arrayForPreviousApplication[index - 1] == undefined) {
          setSelectedDropDownItem(arrayForPreviousApplication[0]);
          if (arrayForPreviousApplication[0].application_status == 1) {
            setApplication_rej_reason(
              arrayForPreviousApplication[0].application_reject_reason
            );
            setApplication_rej_comment(
              arrayForPreviousApplication[0].application_reject_comment
            );
          } else {
            setApplication_rej_reason(null);
            setApplication_rej_comment(null);
          }
          // if(arrayForPreviousApplication[0].application_status==1){
          //     setCommentValue()
          // }
          setButtonDisable(true);
        } else {
          arrayForPreviousApplication[index - 1].color = "white";
          arrayForPreviousApplication[index - 1].background = "#057092";

          setSelectedDropDownItem(arrayForPreviousApplication[index - 1]);
          if (arrayForPreviousApplication[index - 1].application_status == 1) {
            setApplication_rej_reason(
              arrayForPreviousApplication[index - 1].application_reject_reason
            );
            setApplication_rej_comment(
              arrayForPreviousApplication[index - 1].application_reject_comment
            );
          } else {
            setApplication_rej_reason(null);
            setApplication_rej_comment(null);
          }
        }
      }
    });
  };
  const nextApplication = () => {
    if (selectedDropDownItem == null) {
      toast("please select the user", { toastId: random_number });
    } else if (selectedDropDownItem.application_status == 1 || selectedDropDownItem.application_status == 2) {
      setButtonDisable(false);

      arrayForPreviousApplication.map((apptwo, index) => {
        if (selectedDropDownItem._id == apptwo._id) {
          apptwo.color = "#057092";
          apptwo.background = "white";

          //indexStatus(index)
          if (arrayForPreviousApplication.length - 2 == index) {
            setButtonDisableNext(true);
          }
          if (arrayForPreviousApplication[index + 1] == undefined) {
            setSelectedDropDownItem(
              arrayForPreviousApplication[
                arrayForPreviousApplication.length - 1
              ]
            );
            if (
              arrayForPreviousApplication[
                arrayForPreviousApplication.length - 1
              ].application_status == 1
            ) {
              setApplication_rej_reason(
                arrayForPreviousApplication[
                  arrayForPreviousApplication.length - 1
                ].application_reject_reason
              );
              setApplication_rej_comment(
                arrayForPreviousApplication[
                  arrayForPreviousApplication.length - 1
                ].application_reject_comment
              );
            } else {
              setApplication_rej_reason(null);
              setApplication_rej_comment(null);
            }
            // setButtonDisable(false);
          } else {
            arrayForPreviousApplication[index + 1].background = "#057092";
            arrayForPreviousApplication[index + 1].color = "white";
            setSelectedDropDownItem(arrayForPreviousApplication[index + 1]);
            if (
              arrayForPreviousApplication[index + 1].application_status == 1
            ) {
              setApplication_rej_reason(
                arrayForPreviousApplication[index + 1].application_reject_reason
              );
              setApplication_rej_comment(
                arrayForPreviousApplication[index + 1]
                  .application_reject_comment
              );
            } else {
              setApplication_rej_reason(null);
              setApplication_rej_comment(null);
            }
          }
        }
      });
    } else {
      setButtonDisable(false);

      setDisableAcceptBtn(true);
      axios
        .post(base_url + "admin/update_program_application_status", {
          user_id: selectedDropDownItem.aws_id,
          application_id: selectedDropDownItem._id,
          application_status: 2,
        })
        .then((res) => {
          toast.success("This User Application Approved!!", {
            toastId: random_number,
          });
          // axios.post(base_url + "user/get_students_for_admin_review", { "course_title": props.item, "page": 1, "pagination": 0 })
          axios
            .post(base_url + "admin/get_program_applications_for_review", {
              program_id: approvalsData.programId,
            })
            .then((res) => {
              setDisableAcceptBtn(false);
              setApplication(res.data.result);
              setGetDetails(res.data.result);

              setSelectedDropDownItem(res.data.result[0]);

              arrayForPreviousApplication.map((apptwo, index) => {
                if (selectedDropDownItem._id == apptwo._id) {
                  //indexStatus(index)
                  if (arrayForPreviousApplication[index + 1] == undefined) {
                    if (arrayForPreviousApplication.length - 1 == index) {
                      setButtonDisableNext(true);
                    }
                    setSelectedDropDownItem(
                      arrayForPreviousApplication[
                        arrayForPreviousApplication.length - 1
                      ]
                    );
                  } else {
                    arrayForPreviousApplication[index + 1].color = "white";
                    arrayForPreviousApplication[index + 1].background =
                      "#057092";
                    setSelectedDropDownItem(
                      arrayForPreviousApplication[index + 1]
                    );
                  }
                }
              });
            });
        })
        .catch((err) => {
          if (err.response?.statusText == "Unauthorized") {
            localStorage.clear();
            history.push("/adminlogin");
            window.location.reload();
          } else if (err.response.status == 400) {
            setFinalData();
            toast.warn("Applications are exceeded the capacity", {
              toastId: random_number,
            });
          } else {
            toast.warn(api_error_message, { toastId: random_number });
          }
        });
    }
  };

  const [isAlumniVerified, setIsAlumniVerified] = useState(false);

  const handleAlumniCheckbox = (e) => {
    setIsAlumniVerified(e.target.checked);
  };


const [showModal, setShowModal]  = useState(false)

const handleAcceptAlumni=()=>{
  if(isAlumniVerified){
    const objec = {
      "user_id": selectedDropDownItem.aws_id,
      "email": selectedDropDownItem.email,
      "is_verified_alumni": true
    }
    axios
      .post(base_url + "sfdc/isb/user/update_alumni_status", objec)
      .then((res) => {
        setShowModal(false)
        approveApplication()
      })
  } 
}

  
  const applicationAccept = () => {
    if(isAlumniVerified){

      setShowModal(true)
    }else{
      approveApplication()
   
  }
  };

  const approveApplication=()=>{
    if (selectedDropDownItem == null) {
      toast("please select the user", { toastId: random_number });
    } else if (selectedDropDownItem.application_status == 2) {
      setStatus(2);
      setButtonDisable(false);
      arrayForPreviousApplication.map((apptwo, index) => {
        if (selectedDropDownItem._id == apptwo._id) {
          apptwo.color = "#057092";
          apptwo.background = "white";

          //indexStatus(index)
          if (arrayForPreviousApplication.length - 2 == index) {
            setButtonDisableNext(true);
          }
          if (arrayForPreviousApplication[index + 1] == undefined) {
            setSelectedDropDownItem(
              arrayForPreviousApplication[
                arrayForPreviousApplication.length - 1
              ]
            );
          } else {
            arrayForPreviousApplication[index + 1].background = "#057092";
            arrayForPreviousApplication[index + 1].color = "white";
            setSelectedDropDownItem(arrayForPreviousApplication[index + 1]);
            if (
              arrayForPreviousApplication[index + 1].application_status == 1
            ) {
              setApplication_rej_reason(
                arrayForPreviousApplication[index + 1].application_reject_reason
              );
              setApplication_rej_comment(
                arrayForPreviousApplication[index + 1]
                  .application_reject_comment
              );
            } else {
              setApplication_rej_reason(null);
              setApplication_rej_comment(null);
            }
          }
        }
      });
    } else {
      setButtonDisable(false);
      setDisableAcceptBtn(true)
      axios
        .post(base_url + "admin/update_program_application_status", {
          user_id: selectedDropDownItem.aws_id,
          application_id: selectedDropDownItem._id,
          application_status: 2,
        })
        .then((res) => {
if(!isAlumniVerified){
  const objec = {
    "user_id": selectedDropDownItem.aws_id,
    "email": selectedDropDownItem.email,
    "is_verified_alumni": false
  }
  axios
    .post(base_url + "sfdc/isb/user/update_alumni_status", objec)
    .then((res) => {

    }) .catch((err) => {
      if (err.response?.statusText == "Unauthorized" ||err.response?.data?.statusText == "Unauthorized") {
        localStorage.clear();
        history.push("/adminlogin");
        window.location.reload();
      }  
    });
}
         
            

          toast.success("This User Application Approved!!", {
            toastId: random_number,
          });
          // axios.post(base_url + "user/get_students_for_admin_review", { "course_title": approvalsData.item, "page": 1, "pagination": 0 })
          axios
            .post(base_url + "admin/get_program_applications_for_review", {
              program_id: approvalsData.programId,
            })
            .then((res) => {
              setDisableAcceptBtn(false)
              let foundProfiles = res.data.result.filter((app) => {
                if(dropDownValue == "all"){
                  return app;
                }else if(app.application_status == dropDownValue){
                  return app
                }
              });
              let application_details = foundProfiles.filter((elem, index) => {
                if (index == 0) {
                  elem.color = "white";
                  elem.background = "#057092";
                } else {
                  elem.color = "#057092";
                  elem.background = "white";
                }
                return elem;
              });
              setApplication(res.data.result);
              setGetDetails(application_details);

              setSelectedDropDownItem(application_details[0]);
              arrayForPreviousApplication.map((apptwo, index) => {
                if (selectedDropDownItem._id == apptwo._id) {
                  //indexStatus(index)
                  if (arrayForPreviousApplication[index + 1] == undefined) {
                    if (arrayForPreviousApplication.length - 2 == index) {
                      setButtonDisableNext(true);
                    }
                    setSelectedDropDownItem(
                      arrayForPreviousApplication[
                        arrayForPreviousApplication.length - 1
                      ]
                    );
                  }
                  // } else {
                  //   let application_details = res.data.result.filter(
                  //     (elem, index) => {
                  //       if (index == 0) {
                  //         elem.color = "white";
                  //         elem.background = "#057092";
                  //       } else {
                  //         elem.color = "#057092";
                  //         elem.background = "white";
                  //       }
                  //       return elem;
                  //     }
                  //   );
                  //   // if (application_details.length > 0) {
                  //   //   setGetDetails(application_details);
                  //   //   setSelectedDropDownItem(application_details[0]);
                  //   // } else {
                  //   //   setGetDetails([]);
                  //   //   setSelectedDropDownItem(null);
                  //   // }
                  // }
                }
              });
            });
           
        })
        .catch((err) => {
          if (err.response?.statusText == "Unauthorized" ||err.response?.data?.statusText == "Unauthorized") {
            localStorage.clear();
            history.push("/adminlogin");
            window.location.reload();
          } else if (err?.response?.status == 400) {
            setFinalData();
            toast.warn("Applications are exceeded the capacity", {
              toastId: random_number,
            });
          } else {
            toast.warn(api_error_message, { toastId: random_number });
          }
        });
    }
  }
  const handleClearedDateChange = (date) => {
    if(date ===null){
      date = new Date()
    }
    setClearedDate(date);
    if(date == "Invalid Date"){
      setIsSubmitted(true);
    }else{
      setIsSubmitted(false);
      //call extend date api
      const {amount_paid,cost,aws_id,program_id}=selectedDropDownItem
      axios.post(base_url+'admin/extending_payment_date',{
        "user_id":aws_id,
        "program_id":program_id,
        "extendeddate":parseInt(moment(date).valueOf())
      }).then(res=>{
        toast(res.data, { toastId: random_number });
        setDateoraccessUpdate(true)
      })
      .catch(err=>{
        console.log(err)
      })
    }
  };
  const handleCheckboxChange=(e)=>{
    const {name,checked}=e.target
    if(name=="RemoveAccess")
    {
      setIsRemoveChecked(checked)
      if(checked)
      {
        //call Remove access modal
        setRemoveAccessModal(true)
      }  
    }
    else if(name=="ExtendDate")
    {
      setIsExtendDateChecked(checked) 
    }
    else{ //Revoke Access
      setIsRevokeChecked(checked) 
      //call revoke access api
      if(checked)
      {
        setRevokeAccessModal(true)
      }
      else{
        if(selectedDropDownItem.is_banned) //temporarily_revoked)
        {
          setRetainAccessModal(true)
        }
      }
    }
  }

  const itemChanged = (item) => {
    if (item.application_status == 1) {
      setApplication_rej_reason(item.application_reject_reason);
      setApplication_rej_comment(item.application_reject_comment);
    } else {
      setApplication_rej_reason(null);
      setApplication_rej_comment(null);
    }

    arrayForPreviousApplication.map((apptwo, index) => {
      if (item._id == apptwo._id) {
        apptwo.color = "white";
        apptwo.background = "#057092";
      } else {
        apptwo.color = "#057092";
        apptwo.background = "white";
      }
    });

    if(arrayForPreviousApplication.length == 1  && dropDownValue == 0){
        setButtonDisableNext(false);
        setButtonDisable(true)
      }else if(arrayForPreviousApplication.length == 1  && dropDownValue != 0){
        setButtonDisableNext(true);
        setButtonDisable(true)
      }else{
        setButtonDisableNext(false);
        setButtonDisable(false);
        setSelectedDropDownItem(item);
      }
      if(dropDownValue == 'all'&& item.application_status==3)
      {
        setButtonDisableNext(true);
      }
  };

  const setFinalData = () => {
    axios
      .post(base_url + "admin/get_program_applications_for_review", {
        program_id: approvalsData.programId,
      })
      .then((res) => {
        res.data.result.map((elem, index) => {
          if (index == 0) {
            elem.color = "white";
            elem.background = "#057092";
          } else {
            elem.color = "#057092";
            elem.background = "white";
          }
        });
        setGetDetails(res.data.result);
        setApplication(res.data.result);
        setSelectedDropDownItem(res.data.result[0]);
      })
      .catch((err) => {
        if (err.response?.statusText == "Unauthorized" || err.response?.data?.statusText == "Unauthorized") {
          localStorage.clear();
          history.push("/adminlogin");
          window.location.reload();
        } else {
          toast.warn(api_error_message, { toastId: random_number });
        }
      });
  };
 
  useEffect(() => {
    if(selectedDropDownItem?.is_verified_alumni){
      setIsAlumniVerified(true)
    }else{
      setIsAlumniVerified(false)

    }
  },[selectedDropDownItem])
  useEffect(() => {
    // axios.post(base_url + "user/get_students_for_admin_review", { "course_title": approvalsData?.item, "page": 1, "pagination": 0 })
    axios
      .post(base_url + "admin/get_program_applications_for_review", {
        program_id: approvalsData?.programId,
      })
      .then((res) => {
        let foundProfiles = res.data.result.filter((app) => {
            if (app.application_status == 0) return app;
          });
          let application_details = foundProfiles.filter((elem, index) => {
            if (index == 0) {
              elem.color = "white";
              elem.background = "#057092";
            } else {
              elem.color = "#057092";
              elem.background = "white";
            }
            return elem;
          });
          setApplication(res.data.result);
          if(application_details.length == 1  && dropDownValue == 0){
            setButtonDisableNext(false);
            setButtonDisable(true)
          }else if(application_details.length == 1  && dropDownValue != 0){
            setButtonDisableNext(true);
            setButtonDisable(true)
          }else{
            setButtonDisableNext(false);
            setButtonDisable(false)
          }

          if (application_details.length > 0) {
            setGetDetails(application_details);
            setSelectedDropDownItem(application_details[0]);
          } else {
            setGetDetails([]);
            setSelectedDropDownItem(null);
          }
        Setloading_flag(false);
      })
      .catch((err) => {
        if (err.response?.statusText == "Unauthorized" || err.response?.data?.statusText == "Unauthorized") {
          localStorage.clear();
          history.push("/adminlogin");
          window.location.reload();
        } else {
          toast.warn(api_error_message, { toastId: random_number });
        }
      });
  }, []);

  const handleDetails = (e) => {
    setIsRemoveChecked(false)
    setIsExtendDateChecked(false)
    setIsRevokeChecked(false)
    setDropDown(true);
    setDropdownValue(e.target.value);
    if (e.target.value === "all") {
      setGetDetails(application);
      if (application[0] == undefined) {
        setSelectedDropDownItem({});
      } else {
        let application_details = application.filter((elem, index) => {
          if (index == 0) {
            elem.color = "white";
            elem.background = "#057092";
          } else {
            elem.color = "#057092";
            elem.background = "white";
          }
          return elem;
        });
        setSelectedDropDownItem(application_details[0]);
      }
  
      if(application.length == 1  && application[0].application_status == 0){
        setButtonDisableNext(false);
        setButtonDisable(true)
      }else if(application.length == 1  && application[0].application_status != 0){
        setButtonDisableNext(true);
        setButtonDisable(true)
      }else{
        setButtonDisableNext(false);
        setButtonDisable(false);
      }


    } else {
      let foundProfiles = application.filter((app) => {
        if (app.application_status == e.target.value) return app;
      });
      let application_details = foundProfiles.filter((elem, index) => {
        if (index == 0) {
          elem.color = "white";
          elem.background = "#057092";
        } else {
          elem.color = "#057092";
          elem.background = "white";
        }
        return elem;
      });
      if(application_details.length == 1  && e.target.value == 0){
        setButtonDisableNext(false);
        setButtonDisable(true)
      }else if(application_details.length == 1  && e.target.value != 0){
        setButtonDisableNext(true);
        setButtonDisable(true)
      }else{
        setButtonDisableNext(false);
        setButtonDisable(false)
      }

      if (application_details.length > 0) {
        setGetDetails(application_details);
        setSelectedDropDownItem(application_details[0]);
      } else {
        setGetDetails([]);
        setSelectedDropDownItem(null);
      }
    }
  };

  useEffect(() => {
    if(dateoraccessUpdate==true)
    {
      axios
        .post(base_url + "admin/get_program_applications_for_review", {
          program_id: approvalsData?.programId,
        })
        .then((res) => {
          setDateoraccessUpdate(false)
          let foundProfiles = res.data.result.filter((app) => {
              if (dropDownValue=="all")
              {
                return app
              }
              else{
                if(app.application_status == dropDownValue) return app;
              } 
            });
            let application_details = foundProfiles.filter((elem, index) => {
              if (index == 0) {
                elem.color = "white";
                elem.background = "#057092";
              } else {
                elem.color = "#057092";
                elem.background = "white";
              }
              return elem;
            });
            setApplication(res.data.result);
            if(application_details.length == 1  && dropDownValue == 0){
              setButtonDisableNext(false);
              setButtonDisable(true)
            }else if(application_details.length == 1  && dropDownValue != 0){
              setButtonDisableNext(true);
              setButtonDisable(true)
            }else{
              setButtonDisableNext(false);
              setButtonDisable(false)
            }
  
            if (application_details.length > 0) {
              setGetDetails(application_details);
              setSelectedDropDownItem(application_details[0]);
            } else {
              setGetDetails([]);
              setSelectedDropDownItem(null);
            }
          Setloading_flag(false);
        })
        .catch((err) => {
          if (err.response?.statusText == "Unauthorized" || err.response?.data?.statusText == "Unauthorized") {
            localStorage.clear();
            history.push("/adminlogin");
            window.location.reload();
          } else {
            toast.warn(api_error_message, { toastId: random_number });
          }
        });
    }
  }, [dateoraccessUpdate]);
  
  const handleStatus = () => {
    if (selectedDropDownItem == null) {
      toast("please select the user", { toastId: random_number });
    } else if (selectedDropDownItem.application_status == 2) {
      toast.error("This Application is already approved, cannot reject", {
        toastId: random_number,
      });
    } else if (selectedDropDownItem.application_status == 1) {
      toast.error("This user Application is already rejected", {
        toastId: random_number,
      });
    } else if (rej_value == "") {
      toast.warning("please mention the reason for rejection", {
        toastId: random_number,
      });
    } else {
      setStatus(1);
      axios
        .post(base_url + "admin/update_program_application_status", {
          user_id: selectedDropDownItem.aws_id,
          application_id: selectedDropDownItem._id,
          application_status: 1,
          reject_reason: rej_value,
          reject_comment: comment_vlaue,
        })
        .then((res) => {
          // axios.post(base_url + "user/get_students_for_admin_review", { "course_title": approvalsData.item, "page": 1, "pagination": 0 })
          axios
            .post(base_url + "admin/get_program_applications_for_review", {
              program_id: approvalsData.programId,
            })
            .then((res) => {
              setApplication(res.data.result);
              // setGetDetails(res.data.result);
              toast.success("This User Application is Rejected !!", {
                toastId: random_number,
              });
              let foundProfiles = res.data.result.filter((app, index) => {
                if (
                  app.application_status == dropDownValue ||
                  dropDownValue == "all"
                ) {
                  if (index == 0) {
                    app.color = "white";
                    app.background = "#057092";
                  } else {
                    app.color = "#057092";
                    app.background = "white";
                  }
                  return app;
                }
              });
              if (foundProfiles.length > 0) {
                setGetDetails(foundProfiles);
                setSelectedDropDownItem(foundProfiles[0]);
              } else {
                setGetDetails([]);
                setSelectedDropDownItem(null);
              }
              setRejValue(" ");
              setCommentValue(" ");
            });
        })
        .catch((err) => {
          if (err.response?.statusText == "Unauthorized") {
            localStorage.clear();
            history.push("/adminlogin");
            window.location.reload();
          } else {
            toast.warn(api_error_message, { toastId: random_number });
          }
        });
    }
  };
  const getRejection = (e) => {
    setRejValue(e.target.value);
  };
  const getComment = (e) => {
    setCommentValue(e.target.value);
  };
  const removeAccessHandler=()=>{
    const {amount_paid,cost,aws_id,program_id}=selectedDropDownItem
    axios.post(base_url+'admin/revoke_access_permanently',{
      "user_id":aws_id,
      "program_id":program_id,
      "revoke_access" : true
    }).then(res=>{
      toast(res.data, { toastId: random_number });
      setRemoveAccessModal(false)
      setIsRemoveChecked(false)
      setDateoraccessUpdate(true)
    })
    .catch(err=>{
      console.log(err)
    })
  }
  const revokeAccessHandler=()=>{
    const {amount_paid,cost,aws_id,program_id}=selectedDropDownItem
    axios.post(base_url+'admin/revoke_access_temporarily',{
      "user_id":aws_id,
      "program_id":program_id,
      "revoke_access" : true
    }).then(res=>{
      toast(res.data, { toastId: random_number });
      setRevokeAccessModal(false)
      setIsRevokeChecked(false)
      setDateoraccessUpdate(true)
    })
    .catch(err=>{
      console.log(err)
    })
  }
  
  const retainAccessHandler=()=>{
    const {amount_paid,cost,aws_id,program_id}=selectedDropDownItem
    axios.post(base_url+'admin/revoke_access_temporarily',{
      "user_id":aws_id,
      "program_id":program_id,
      "revoke_access" : false
    }).then(res=>{
      toast(res.data, { toastId: random_number });
      setRetainAccessModal(false)
      setIsRevokeChecked(false)
      setDateoraccessUpdate(true)
    })
    .catch(err=>{
      console.log(err)
    })
  }

  const onRevokeAccessCancel = () =>{
    setRevokeAccessModal(false)
    setIsRevokeChecked(selectedDropDownItem.is_banned) //temporarily_revoked)
  }
  
  const onRemoveAccessCancel = () =>{
    setRemoveAccessModal(false);
    setIsRemoveChecked(false)
  }
  const onRetainAccessCancel=()=>{
    setIsRevokeChecked(selectedDropDownItem.is_banned) //temporarily_revoked)
    setRetainAccessModal(false);
  }

  const [searchItem, setSearchItem] = useState("");

  const [viewMoreDiv, setViewMoreDiv] = useState(false)
  const [viewLessDiv, setViewLessDiv] = useState(false)
  
  const handleViewMore =()=>{
    setViewMoreDiv(true)
    setViewLessDiv(false)
  }
  const handleViewLess =()=>{
    setViewLessDiv(true)
    setViewMoreDiv(false)
  }
  return (
    <>
      {loading_flag ? (
        <>
          <div>
            <img
              className="atrium_loader"
              src="/images/atrium_loader.gif"
              alt="Loader"
            />
          </div>
        </>
      )  : (
        <>
          {" "}
          <Container className="bg-transparent">
            <img src="/images/admissionapproval.png" alt="" />
          </Container>
          <Container
            style={{
              background: "none",
              border: "0",
              justifyContent: "center",
              marginTop: "0px",
            }}
          >
            <div className="row">
              <div className="col-lg-4 mt-3 p-2">
                <div className="two p-3">
                  <div className="form-group">
                    <select
                      id="inputState"
                      data-testid='application_dropdown'
                      className="form-select form-select mb-2"
                      aria-label=".form-select example"
                      onChange={handleDetails}
                      value={dropDownValue}
                    >
                      <option value="all">All Applications</option>
                      {applicationStatus.map((app) => {
                        {
                          /* if(app.application_status==0){ */
                        }
                        return (
                          <option key={app.id} value={app.id}>
                            {app.status}
                          </option>
                        );
                        {
                          /* } */
                        }
                      })}
                    </select>
                  </div>
                  <div className="inputWrapper">
                    <input
                      className="form-control custm_input_search"
                      type="text"
                      placeholder="Type to Search"
                      aria-label="Search"
                      autoFocus
                      data-testid="setsearchitem"
                      onChange={(e) => setSearchItem(e.target.value)}
                    />
                    <i className="input_Mag_icon fa-solid fa-magnifying-glass"></i>
                  </div>
                  <div className="applicant_div">
                    {getDetails
                      .filter((user) =>
                        user?.first_name.trim().toLowerCase().includes(searchItem.trim().toLowerCase())
                      )
                      .map((apptwo, index) => {
                        apptwo.application_status == 0
                          ? (apptwo.application_statusName = "Pending")
                          : apptwo.application_status == 1
                          ? (apptwo.application_statusName = "Rejected")
                          : apptwo.application_status == 2
                          ? (apptwo.application_statusName = "Approved")
                          : (apptwo.application_statusName = "Banned");
                        return (
                          <div
                            style={{
                              color: apptwo.color,
                              background: apptwo.background,
                            }}
                            data-testid={`itemChanged_${index}`}
                            onClick={() => itemChanged(apptwo)}
                            className="aplcant_name rounded mt-3 "
                            key={apptwo._id}
                          >
                            {
                              <span className="txt_prpr_cls">
                                {apptwo?.first_name?.toString().length > 15
                                  ? apptwo?.first_name?.slice(0, 15) + "..."
                                  : apptwo?.first_name}
                              </span>
                            }
                            <span>{apptwo.application_statusName}</span>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
              { getDetails.length != 0 ?  (<>
              <div className="col-lg-8 p-2 mt-3">
                <div className="d-flex mb-3 p-3 profile_table">
                  <div className="col-lg-4 ">
                    {" "}
                    <p className="applied_for_p">
                      <b>Applied For</b>
                    </p>
                  </div>
                  {/* <div className='col-lg-4 '><b>{selectedDropDownItem?.learning_track_title}</b></div> */}
                  <div className="col-lg-4 ">
                    <b>
                      {selectedDropDownItem?.learning_track_title}
                      {selectedDropDownItem !== null && (
                        <span className="pl-2" key={selectedDropDownItem?._id}>
                          {date_formats.human_date_format(
                            selectedDropDownItem["program_start_date"]
                          )}
                        </span>
                      )}
                    </b>
                  </div>
                </div>

                <div className="col-lg-12 col-lg-12 col-sm-12 mb-3 p-3 profile_table">
                  {selectedDropDownItem !== null && (
                    <div
                      style={{ overflowY: "auto" }}
                      className="row"
                      key={selectedDropDownItem._id}
                    >
                      <div className="col-lg-4 "><span className="Details_text pl-3">Details</span></div>
                      <div className="col-lg-4 ">
                        <span className="word_break_span">
                          <b>{selectedDropDownItem.first_name}</b>
                          <br />
                          {selectedDropDownItem.email} <br />
                          {" " + selectedDropDownItem?.mobile
                            ? selectedDropDownItem?.mobile
                            : "--"}
                        </span>
                        <p />
                        <span>
                          <br />
                          <span className="word_break_span">
                            <b>{selectedDropDownItem?.age}</b>{" "}
                            {selectedDropDownItem?.age && "Years Old"}
                          </span>
                          <br />
                          {selectedDropDownItem?.dob && "DOB:"}
                          {selectedDropDownItem?.dob
                            ? date_formats.human_date_format(
                                selectedDropDownItem.dob
                              )
                            : ""}
                        </span>
                        <br />
                        {selectedDropDownItem.course_title}
                      </div>
                      <div className="col-lg-4 ">
                        <span className="txt_prpr_cls word_break_span">
                          {selectedDropDownItem.city}
                          {selectedDropDownItem.city && ", "}
                          {selectedDropDownItem?.state}
                          {selectedDropDownItem.state && ","}
                          <br />
                          {selectedDropDownItem.country}
                          {selectedDropDownItem.country && ","}{" "}
                          {selectedDropDownItem.postcode}
                          {selectedDropDownItem.postcode && ","}{" "}
                        </span>
                        <br />
                        <span className="txt_prpr_cls word_break_span">
                        {selectedDropDownItem.company}
                          {selectedDropDownItem.company && ", "}
                          {selectedDropDownItem.industry}
                          {selectedDropDownItem.industry && ", "}
                          {selectedDropDownItem.role}
                          {selectedDropDownItem.role && ","}{" "}
                          {selectedDropDownItem.years_company >=2 ? ("(" +selectedDropDownItem.years_company + " Years,")
                          :("(" +selectedDropDownItem.years_company + " Year") +"),"}                          
                          <br />
                          {selectedDropDownItem.total_years ? "Total Experience " :""}
                          {selectedDropDownItem.total_years >=2 ? ("(" + selectedDropDownItem.total_years + " Years)") : ("("+selectedDropDownItem.total_years + " Year)")}
                        </span>
                        <span className="txt_prpr_cls word_break_span">
                        {(selectedDropDownItem.total_years && selectedDropDownItem.high_institute) ? "," :""}{" "} 
                          {selectedDropDownItem.high_institute}
                          {selectedDropDownItem.graduation_year && ","}{" "}
                          {selectedDropDownItem?.graduation_year}
                        </span>
                      </div>
                    </div>
                  )}

                {(selectedDropDownItem.is_isb_alumni) &&  <div className="">
                  <h6>Alumni Details</h6>
                    <div className="d-flex justify-content-between">
                      <div>{selectedDropDownItem.alumni_email}</div>
                      <div>{selectedDropDownItem.alumni_programme_name}</div>
                      <div>{selectedDropDownItem.alumni_programme_year}</div>
                      {/* <div>{selectedDropDownItem.is_isb_alumni}</div> */}
                          <div className="d-flex gap-2">
                            <label htmlFor="">Is verified alumni</label>
                            <div className="form-check">
                              <input name="additionalread"
                                data-testid="Additional-Read"
                                onChange={handleAlumniCheckbox}
                                checked={selectedDropDownItem.is_verified_alumni ? true : isAlumniVerified}
                                type="checkbox"
                                className="form-check-input additionalread"
                                id="additionalread"
                              />
                            </div>
                          </div>
                    </div>
                  </div>}
                      <div data-testid="viewmore_testid" className={`view_class ${viewMoreDiv ? 'd-none' : ""}`} onClick={handleViewMore}>view more</div>

                      {
                        viewMoreDiv &&

                        <div>
                          <div className="rounded bg_isb_gray p-3 mb-3">
                            <span>
                              {selectedDropDownItem.description}
                            </span>

                          </div>
                          <div className="container overflow-hidden text-center">
                            <div className="row text-start viewmoredtls">
                              <div className="col-6 pb-3">
                                <label className=" pe-2 pb-0 mb-0">Influenced by:</label>
                                <span className=" ">{selectedDropDownItem.influencer}</span>
                              </div>
                              <div className="col-6 pb-3">
                                <label className=" pe-2 pb-0">Reference Through: </label>
                                <span className=" ">{selectedDropDownItem.learn_program}</span>
                              </div>
                              <div className="col-6">
                                <label className=" pe-2 pb-0">Participant Name:</label>
                                <span className=" ">{selectedDropDownItem.participant_name}</span>
                              </div>
                              <div className="col-6">
                                <label className=" pe-2 pb-0">Programme / Year:</label>
                                <span className=" ">{selectedDropDownItem.program_year}</span>
                              </div>
                            </div>
                          </div>
                          <div data-testid="viewless_testid" className={`view_class ${viewLessDiv ? "d-none" : ""}`} onClick={handleViewLess}>view less</div>
                        </div>
                      }
                </div>
                {(selectedDropDownItem.application_status==2)?
                <>
                <div className="d-flex profile_table">
                  <div className="col-lg p-2">
                    <div className="col-lg  p-2">
                      <div className="six">
                        <div className="d-flex justify-content-between">
                        <span className="custm_txt_clr visible-hidden">Previous</span>
                        <span>
                          <b> {selectedDropDownItem?.learning_track_title}</b>{" "}
                          <br />
                        </span>
                        {/* <span className='text-end'><b> {moment(previousCourse.program_start_date).format("DD MMM YYYY")} - {moment(previousCourse.program_end_date).format("DD MMM YYYY")}</b><br />{previousCourse.status} </span> */}
                        {selectedDropDownItem !== null && (
                          <span
                            className="fw-bold"
                            key={selectedDropDownItem._id}
                          >
                            {date_formats.human_date_format(
                              selectedDropDownItem["program_start_date"]
                            )}{" - "}
                            {date_formats.human_date_format(
                              selectedDropDownItem["program_end_date"]
                            )}
                          </span>
                        )}
                      </div>
                      <div className="d-flex justify-content-between mt-4">
                        <div className="d-flex">
                          <input data-testid='handleCheckboxChange' type="checkbox" disabled={selectedDropDownItem.permanent_revoked} checked={isRemoveChecked} onChange={handleCheckboxChange} className="mr-2" name="RemoveAccess" id="checkBox1"/>
                          <label className="cursor-pointer label-without-text-selection mb-1" htmlFor="checkBox1">
                            Remove Access
                          </label>
                        </div>
                        <div>
                        <div className="d-flex">
                          <div className="d-inline-flex">
                              {/* <span>{date_formats.human_date_format(selectedDropDownItem.payment_date)}</span> */}
                          <input type="checkbox" checked={isExtendDateChecked} onChange={handleCheckboxChange} className="mr-2" name="ExtendDate" id="checkBox2"/>
                          <label className="cursor-pointer label-without-text-selection mb-1" htmlFor="checkBox2">
                            Payment Extend Date
                          </label>
                          </div>
                          <div className="adminapprl_date_picker">
                              <KeyboardDateTimePicker
                              className={!isExtendDateChecked ? "pe-none" : 'pe-auto'}
                              clearable
                              value={clearedDate}
                              data-testid='handleClearedDateChange'
                              onChange={handleClearedDateChange}
                              label={''}
                              disablePast={false}
                              format="MMM d, yyyy hh:mm a"
                              inputProps={{
                                readOnly: true,
                              }}
                            />
                          </div>
                        </div>
                        </div>
                        <div>
                          <div className="d-flex">
                            <input type="checkbox" data-testid='handleCheckboxChangeRevokeAccess' checked={isRevokeChecked} onChange={handleCheckboxChange} className="mr-2" name="RevokeAccess" id="checkBox3"/>
                            <label className="cursor-pointer label-without-text-selection mb-1" htmlFor="checkBox3">
                              Revoke Access
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between mt-2">
                      <button
                        disabled={buttonDisable}
                        data-testid='previousstudent'
                        className="btn btn-secondary my_btn_cls"
                        onClick={PreviousApplication}
                      >
                        Previous Student
                      </button>
                      {/* <Link to='/AdminApprovalFlowTwo' style={{textDecoration:"none"}}> */}
                      {selectedDropDownItem?.application_status == 2 ||
                      selectedDropDownItem?.application_status == 1 ? (
                        <button
                          disabled={buttonDisableNext}
                          data-testid='nextApplication'
                          className="btn btn-secondary my_btn_cls"
                          onClick={nextApplication}
                        >
                          Next Student
                        </button>
                      ) : (
                        <button
                          disabled={buttonDisableNext || disableAcceptBtn}
                          data-testid='applicationAccept'
                          className="btn btn-secondary my_btn_cls"
                          onClick={applicationAccept}
                        >
                          Admit and Next
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
                </>
              :
              <>
                 <div className="d-flex profile_table">
                  <div className="col-lg p-2">
                    <div className="col-lg  p-2">
                      <div className="six">
                        <div className="d-flex justify-content-between">
                          <span className="custm_txt_clr">Previous ss</span>
                          <span className="custm_txt_clr">Previous</span>
                          <span>
                            <b> {selectedDropDownItem?.learning_track_title}</b>{" "}
                            <br />
                            INR {selectedDropDownItem?.cost}/-
                          </span>
                          {/* <span className='text-end'><b> {moment(previousCourse.program_start_date).format("DD MMM YYYY")} - {moment(previousCourse.program_end_date).format("DD MMM YYYY")}</b><br />{previousCourse.status} </span> */}
                          {selectedDropDownItem !== null && (
                            <span
                              className="fw-bold"
                              key={selectedDropDownItem._id}
                            >
                              {date_formats.human_date_format(
                                selectedDropDownItem["program_start_date"]
                              )}{" "}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="d-flex justify-content-between mt-2">
                        <button
                          disabled={buttonDisable}
                          data-testid='previousapplicationstatus1'
                          className="btn btn-secondary my_btn_cls"
                          onClick={PreviousApplication}
                        >
                          Previous Student
                        </button>
                        {/* <Link to='/AdminApprovalFlowTwo' style={{textDecoration:"none"}}> */}
                        {selectedDropDownItem?.application_status == 2 ||
                        selectedDropDownItem?.application_status == 1 ? (
                          <button
                            disabled={buttonDisableNext}
                            data-testid='nextApplicationstatus1'
                            className="btn btn-secondary my_btn_cls"
                            onClick={nextApplication}
                          >
                            Next Student
                          </button>
                        ) : (
                          <button
                            disabled={buttonDisableNext || disableAcceptBtn}
                            data-testid='nextApplicationadminandnext'
                            className="btn btn-secondary my_btn_cls"
                            onClick={applicationAccept}
                          >
                            Admit and Next
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                </>
               }

            {showRemoveAccessModal && (
                    <Modal  size="sm" dialogClassName="removeAccess_modal" show={showRemoveAccessModal} centered style={{backgroundColor: "rgba(0, 0, 0, 0.3)"}}>
                      <Modal.Body >
                        <div className="text-center">
                        <p className="modal_text fw-semibold">Are you sure you want to remove?</p>
                        </div>
                        <div className="text-center  redo_btn_container">
                        <button data-testid='onremoveaccesscancel' className="btn review_quiz_button my_outline_button_cls submit_button fw-bold  my_redo_quiz_btn m-0 px-4" onClick={onRemoveAccessCancel}><b>Cancel</b></button>&nbsp;&nbsp;
                        <button data-testid='removeAccessHandler' className="btn my_btn_cls submit_button m-0 px-4 fw-bold" onClick={removeAccessHandler}>Ok</button>
                        </div>
                      </Modal.Body>
                    </Modal>
                  )}
            {showRevokeAccessModal && (
                    <Modal size="sm" show={showRevokeAccessModal} centered id="revokeAccess_modal" style={{backgroundColor: "rgba(0, 0, 0, 0.3)"}}>
                      <Modal.Body >
                        <div className="text-center">
                        <p className="modal_text fw-semibold">Are you sure you want to revoke?</p>
                        </div>
                        <div className="text-center  redo_btn_container">
                        <button data-testid='onRevokeAccessCancel' className="btn review_quiz_button my_outline_button_cls submit_button fw-bold  my_redo_quiz_btn m-0 px-4" onClick={onRevokeAccessCancel}><b>Cancel</b></button>&nbsp;&nbsp;
                        <button data-testid='revokeAccessHandler' className="btn my_btn_cls submit_button m-0 px-4 fw-bold" onClick={revokeAccessHandler}>Ok</button>
                        </div>
                      </Modal.Body>
                    </Modal>
                  )}
            {showRetainAccessModal && (
                    <Modal size="sm" show={showRetainAccessModal} centered id="revokeAccess_modal" style={{backgroundColor: "rgba(0, 0, 0, 0.3)"}}>
                      <Modal.Body >
                        <div className="text-center">
                        <p className="modal_text fw-semibold">Are you sure you want to retain access?</p>
                        </div>
                        <div className="text-center  redo_btn_container">
                        <button data-testid='onRetainAccessCancel' className="btn review_quiz_button my_outline_button_cls submit_button fw-bold  my_redo_quiz_btn m-0 px-4" onClick={onRetainAccessCancel}><b>Cancel</b></button>&nbsp;&nbsp;
                        <button data-testid='retainAccessHandler' className="btn my_btn_cls submit_button m-0 px-4 fw-bold" onClick={retainAccessHandler}>Ok</button>
                        </div>
                      </Modal.Body>
                    </Modal>
                  )}
                {selectedDropDownItem?.application_status == 1 ? (
                  <div className="mt-3">
                    This Application is rejected with reason:{" "}
                    <span className="mt-2 fw-bold">
                      {" "}
                      {application_rej_reason}
                    </span>
                    <br />
                    <br />
                    This Application is rejected with comment:{" "}
                    <span className="mt-3 fw-bold">
                      {application_rej_comment}
                    </span>{" "}
                  </div>
                ) : selectedDropDownItem?.application_status == 2 ? (
                  ""
                ) : selectedDropDownItem?.application_status == 0 ? (
                  <>
                    <div
                      className="mt-3"
                      style={{
                        background: "none",
                        border: "none",
                        marginTop: "80px",
                      }}
                    >
                      <div className="row">
                        <div className="col-lg p-2 ">
                          <div className="col-lg p-2">
                            <div className="seven">
                              <span className="">
                                If you want to Reject this admission, type a
                                reason and click Send Rejection. An Email will
                                be sent to candidate.
                              </span>
                              <div className="">
                                <textarea
                                  name="rejct_reasn_box"
                                  id=""
                                  cols="30"
                                  value={rej_value}
                                  data-testid='getRejection'
                                  rows="10"
                                  placeholder="Reject Reason- This will be visible to candidate"
                                  onChange={(e) => getRejection(e)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        background: "none",
                        border: "none",
                        marginTop: "15px",
                      }}
                    >
                      <div className="row">
                        <div className="col-lg p-2">
                          <div className="col-lg p-2">
                            <div className="eight">
                              <span className="">
                                Comments: This is not shown to candidate
                              </span>
                              <div className="">
                                <textarea
                                  name="comment_box"
                                  data-testid='getcomment'
                                  id=""
                                  cols="30"
                                  value={comment_vlaue}
                                  rows="10"
                                  placeholder="Not shown to candidate"
                                  onChange={(e) => getComment(e)}
                                ></textarea>
                              </div>
                              <button
                              data-testid='send_rejection'
                                className="send_rej_btn btn btn-danger mt-2 rounded"
                                onClick={handleStatus}
                              >
                                Send Rejection
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <span className="text-center fw-bold">
                    'This application is banned'
                  </span>
                )}
              </div> </>):<><div className="col-lg-8 d-flex align-items-center justify-content-center">
            <span style={{ fontWeight: "bold", color: "red" }} className="fs-5">
              No Applications Found
            </span>
          </div></>}
            </div>
<AlumniModal showModal={showModal} setShowModal={setShowModal} handleAcceptAlumni={handleAcceptAlumni}/>
            
          </Container>
        </>
      )}
    </>
  );
};

export default withMaintenanceCheck(AdminApproval,admin_maintenance_type) 



const AlumniModal=({showModal,setShowModal, handleAcceptAlumni })=>{
  return(
    <Modal show={showModal} centered className="role_modal" style={{backgroundColor: "rgba(0, 0, 0, 0.3)"}}>
              <Modal.Body className="p-3">
                <div className="text-center">
                {/* <img  className="mt-4" src="images/payment_icon.svg" /> */}
                <div className="fs-4 fw-bold mt-2 mb-0">Are you sure you want to continue with verified alumni?</div>
                <div className="pb-3 mt-4 d-flex justify-content-center gap-3">
                <button className="btn my_btn_cls submit_button m-0 px-3 fs-18" onClick={handleAcceptAlumni}>Approve</button>
                <button className="btn btn-secondary rounded px-4" onClick={() => setShowModal(false)}>Cancel</button>
                </div>
                </div>
              </Modal.Body>
            </Modal>
  )
}
