import axios from "../axios-interceptor";
import { useHistory } from "react-router-dom";
import { base_url, role_obj,default_dial_code,api_error_message,random_number, user_maintenance_type, storefront_secret_key} from "../utilities";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import withMaintenanceCheck from "../HigherOrderComponents/maintenancePageChecker";
import { useParams } from "react-router-dom";
import CryptoJS from 'crypto-js';

const SocialLoginPage = (props) => {
    var history = useHistory()
    const { learningTrackId, programmeId, utmtags } = useParams()
    localStorage.setItem('apply', "yes")
    localStorage.setItem('learningTrackId', learningTrackId)
    if (programmeId) {
        localStorage.setItem('programId', programmeId)
    }
    let href_url = window.location.href
    let url_arr = href_url.substring(href_url.indexOf('url='))
    let params_arr = href_url.includes('params=') 
    ? href_url.substring(href_url.indexOf('params=') + 'params='.length) 
    : null; 
    
    const decodedURI = params_arr ? decodeURIComponent(params_arr) : null;

    const secretKey = storefront_secret_key;

   const decryptParams = (decodedURI) => {
  try {
    if (decodedURI) {   
      const bytes = CryptoJS.AES.decrypt(decodedURI, secretKey);
      const decryptedString = bytes.toString(CryptoJS.enc.Utf8);

      if (decryptedString) {
        const decryptedData = JSON.parse(decryptedString);
        return decryptedData;
      } else {
        console.warn('Decrypted string is empty or invalid.');
        return null;
      }
    } else {
      console.warn('No params provided for decryption.');
      return null;
    }
  } catch (error) {
    console.error('Error decrypting data:', error.message);
    return null;  
  }
};

const decryptedData = decryptParams(decodedURI);

const containsParamValues = decryptedData && Object.values(decryptedData).every(
    (value) => value !== undefined && value !== 'undefined' && value !== null && value !== ''
  );
    if(url_arr.includes('url=')){
        let modified_url = url_arr.replace('@!@!', '//')
        let final_url =  modified_url.replace('@!', '/');
        let arr =final_url.split('&') 
        arr.shift(); // Remove the first element
        let utmtags = arr.join('&');
        localStorage.setItem('url',final_url.substring(final_url.indexOf('https')) )
        localStorage.setItem('utmtags', utmtags)   
    }
    const cookies = document.cookie.split(';')
    .map(cookie => cookie.trim())
    .reduce((acc, cookie) => {
        const [key, value] = cookie.split('=');
        acc[key] = decodeURIComponent(value);
        return acc;
    }, {});
    const { leadform_name, leadform_email, leadform_mobile, leadform_ProgramId, leadform_role, leadform_country_code,leadform_id } = cookies;
    const IsLoginUser = localStorage.getItem('User_Accesstoken');
    const IsAdminLoginUser = localStorage.getItem('Admin_Acesstoken');
    const loginUser = (leadform_name,leadform_email,leadform_mobile,leadform_ProgramId,leadform_role,leadform_country_code) =>{
        let SaveObj = {
            "name":(decryptedData?.name) ? (decryptedData?.name) : leadform_name,
            "first_name": (decryptedData?.name) ? (decryptedData?.name) : leadform_name,
            "last_name":(decryptedData?.name) ? (decryptedData?.name) : leadform_name, 
            "email": (decryptedData?.email) ? (decryptedData?.email) : leadform_email,
            "mobile": (decryptedData?.mobile)? (decryptedData?.mobile) : leadform_mobile,
            "learning_track_id": localStorage.getItem('learningTrackId'),
            "course_id": localStorage.getItem('free_course_id'),
            "program_id":programmeId,
            "tags": localStorage.getItem('utmtags') ? localStorage.getItem('utmtags') : "",
            "url": localStorage.getItem('url') ? localStorage.getItem('url') : "",
            "is_free_lesson": localStorage.getItem('free_lesson_url') ? true : false,
            "is_terms_accepted": true,
            "role": (decryptedData?.role) ? (decryptedData?.role) : leadform_role 
        }
        axios.post(base_url + 'free_lesson/user_create', SaveObj).then(res => {
            if (res.data.status == 200) {
                const { access_token, refresh_token } = res.data
                const now = new Date();
                const expirationDate = new Date(now.getTime() + 365 * 24 * 60 * 60 * 1000); // one year from now
                document.cookie = `leadform_id=${res.data.user_id}; expires=${expirationDate.toUTCString()}; path=/;`;
                history.push('/onlineapplication')
                localStorage.removeItem('apply')
                window.location.reload();
            }
        }).catch(err => {
            console.log(err);
            if(err.response?.statusText=='Unauthorized'){
                localStorage.clear();
                history.push('/')
                window.location.reload();
            }else{
               toast.warn(api_error_message,{id:random_number});
            }
            // handleDisbleLoaderandSaveBtn(false)
        })
    }
    
    if (IsAdminLoginUser) {
        history.push(`/adminApprovalmodules`)
    }
    else {
        if(localStorage.getItem('user_id') && localStorage.getItem('isLoggedIn')== 'true'){
            if( !leadform_name||!leadform_email||!leadform_mobile||!leadform_ProgramId||!leadform_role ||!leadform_country_code || leadform_ProgramId!=localStorage.getItem('programId')){
                axios
                .post(base_url + "user/my_courses", {
                user_id: localStorage.getItem('user_id')
                })
                .then((res) => {
                let result = res.data.data;
                    const now = new Date();
                    const expirationDate = new Date(now.getTime() + 365 * 24 * 60 * 60 * 1000); // one year from now
                    document.cookie = `leadform_name=${result.first_name.trim()}; expires=${expirationDate.toUTCString()}; path=/;`;
                    document.cookie = `leadform_email=${result.email?.trim()}; expires=${expirationDate.toUTCString()}; path=/;`;
                    document.cookie = `leadform_mobile=${result.mobile}; expires=${expirationDate.toUTCString()}; path=/;`;
                    document.cookie = `leadform_country_code=${result.country_code ? result.country_code : default_dial_code}; expires=${expirationDate.toUTCString()}; path=/;`;
                    document.cookie = `leadform_ProgramId=${programmeId}; expires=${expirationDate.toUTCString()}; path=/;`;
                    document.cookie = `leadform_role=${role_obj.my_self}; expires=${expirationDate.toUTCString()}; path=/;`;
                    loginUser(result.first_name,result.email,result.mobile,programmeId,role_obj.my_self,result.country_code ? result.country_code : default_dial_code)
                })
            }else{
                loginUser(leadform_name,leadform_email,leadform_mobile,leadform_ProgramId,leadform_role,leadform_country_code)
            }
        }else{
            if ((leadform_name && leadform_email && leadform_mobile && leadform_ProgramId && leadform_role && leadform_country_code) || (containsParamValues)) {
                if (leadform_role == role_obj.my_self || decryptedData?.role.toLocaleLowerCase() == role_obj.my_self.toLocaleLowerCase() ) {
                    loginUser(leadform_name,leadform_email,leadform_mobile,programmeId,leadform_role,leadform_country_code)
                } else {
                    document.cookie = "leadform_name=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                    document.cookie = "leadform_email=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                    document.cookie = "leadform_mobile=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                    document.cookie = "leadform_ProgramId=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                    document.cookie = "leadform_role=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                    document.cookie = "leadform_country_code=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                    document.cookie = "leadform_id=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                    history.push('/freelessonlogin');
                    window.location.reload();
                }
            } else {
                document.cookie = "leadform_name=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                document.cookie = "leadform_email=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                document.cookie = "leadform_mobile=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                document.cookie = "leadform_ProgramId=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                document.cookie = "leadform_role=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                document.cookie = "leadform_country_code=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                document.cookie = "leadform_id=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                history.push('/freelessonlogin');
                window.location.reload();
            }
        }
        
    }
}

export default withMaintenanceCheck(SocialLoginPage,user_maintenance_type)