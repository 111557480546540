import React, {useEffect, useState} from 'react';
import axios from '../../axios-interceptor';
import { random_number,base_url,api_error_message } from '../../utilities';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from 'react-router-dom';




const Notification = () => {
    const history = useHistory();
    var authID = localStorage.getItem('user_id');
    const [notificationData, setNotificationData] = useState([])
    const [mark_All_Read, setMark_All_Read] = useState([])
    const [noNotification, setNoNotification] = useState('')
    const [notificStatus, setNotificStatus] = useState([])
    const [newNotifc, setNewNotif] = useState(0)
    let [notify_count, setNotifyCount] = useState(0)

    useEffect(() => {
            if(authID != undefined  ){
        axios.post(base_url + "send_push_notification", {
            "user_id": authID
        })
        .then((res) => {
            if(res.data.data?.notifications){
                 let data = [...res.data.data.notifications].filter(item=>{
                    if(item.body.includes('GMT')){
                        let message =item.body
                        item.body =  message.replace(' ,\n ', ',\n').replace('GMT+0000 (Coordinated Universal Time)','IST')
                       return item
                    }
                    else{
                        return item
                    }
                })
                setNotificStatus(data)
                if(res.data.data.notifications.length < 1){
                    setNoNotification('No new notification')
                }else{
                    setNotifyCount(res.data.data.unread_notifications_count)
                let notifData = res.data.data.notifications;
                notifData.map(notif =>{
                    setNewNotif(notifData.filter(newNotif => newNotif.is_viewed !=undefined && newNotif.is_viewed== 0))
                })
                setNotificationData(res.data.data.notifications) 
                }      
            }else{
                setNoNotification('No new notification')
                setNotificationData(res.data.data) 
            }
        }).catch(err=>{
              localStorage.clear();
              history.push('/')
              window.location.reload();
              console.log(err)
        })
        
    }},[])
const markAllasRead =() =>{
    setNotifyCount(0)
    axios.post(base_url + "update_push_notification", {"user_id": authID })
    .then((res) => { 
        localStorage.setItem('acknowledged', res.data.data.acknowledged)
        setMark_All_Read(res.data.data) 
        axios.post(base_url + "get_push_notification", {
            "user_id": authID 
        })
        .then((getres) => { 
            if(getres.data.data.length<1){
                setNoNotification('No new notification')
            }
            setNotificationData(getres.data.data) 
        }).catch(err=>{
            if(err.response?.statusText=='Unauthorized'){
              localStorage.clear();
              history.push('/')
              window.location.reload();
            }else{
              toast.warn(api_error_message,{toastId:random_number});
            }
            console.log(err)
        })
    }).catch(err=>{
        if(err.response?.statusText=='Unauthorized'){
          localStorage.clear();
          history.push('/')
          window.location.reload();
        }else{
          toast.warn(api_error_message,{toastId:random_number});
        }
        console.log(err)
    })
  
  }





    return (
        <>
        <div>
   
       <div className="btn-group Left-aligned">
    <div className="notifications_bell"  id="navbarDropdownMenuLink"
    role="button" data-bs-toggle="dropdown" aria-expanded="false">
        <a href="#" className="badge rounded-pill badge-notification notific-badge bg-danger">{notify_count > 0 ?notify_count:''}</a><img src="/images/notification_bell_icon.svg"></img>
    </div>
    <div className="dropdown-menu dropdown-menu-end notification_menu" aria-labelledby="navbarDropdownMenuLink">
    <div >
    <div className='d-flex flex-row'>
    <a className="dropdown-item col custm_txt_clr no_cursor no_hover_color p-0" >Notifications  </a>
    { notify_count >0 && (
        <a className='fw-light fs-6 col d-flex align-items-end justify-content-end mark_all_read' onClick={markAllasRead}>{noNotification ? '' : 
        
        <p className='custm_txt_clr'> Mark all as read</p>
        
        }</a>
    )}
    </div>
    <hr className="dropdown-divider"/>
    {notificationData.length < 1 ? noNotification :
        notificationData.map((ndata) =>{
        return(
<div key={ndata._id}>
             
<div>
<div className="dropdown-item d-flex justify-content-start" >
<div>
   
<i className={`${ndata.is_viewed == 0 ? 'fa-solid fa-envelope custm_txt_clr' : 'fa-solid fa-envelope-open custm_txt_clr' } pe-2`}></i>
</div>
<div>
<h5 className='notific_text'>{ndata.title}</h5>
<p className='notific_text'>{ndata.body != 'You earned 0 points' ? ndata.body : ""}</p>
</div>
</div></div>
   
        
        
        <hr className="dropdown-divider"/>
   
    
    </div>
   
    )})}
    </div>
    </div>
</div>    
        </div>
        </>
        
    );
};

export default Notification;