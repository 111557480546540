import { useEffect, useState, Fragment } from "react";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';


export const MessageComponent = (props) => {
    let msg = props.msg;
    let suceess_msg = props.suceess_msg;
    let [points_data, setPointsData] = useState()
    let [contentArr, setcontentArr] = useState([])
    let [answerImgISAvailable, setAnswerImgISAvailable] = useState(false)
    let [currentWindowWidth,setCurrentWindowWidth]=useState(0)

    useEffect(() => {
        handleResize();
        // console.log(suceess_msg,'called',props.feedbackContent,msg)
        if (props.feedbackContent) {
            props.feedbackContent.map((item, index) => {
                if (index == 0 && item.type == "p" && item.children[0].text !== '') {
                    setAnswerImgISAvailable(true)
                }
            })
            setcontentArr(props.feedbackContent)
        }
        if (props.points) {
            setPointsData(props.points)
        }
    }, [props.points])

    const handleResize=() =>{
        setCurrentWindowWidth(window.innerWidth)
        // Perform any other actions based on the updated window width
      }
      // Add event listener for resize event
      window.addEventListener("resize", handleResize);
    return (
        <>
            <div data-testid="message_component" className={msg == "yes" ? "container-success" : (msg == "" ? "" : "container-error")} >
                {props.points?.length != 0  &&  <> {props.points.free_lesson == "1" ? msg == 'yes' ? points_data?.length !==0  && (<span data-testid="feedback_correctans" className="ml-2">Feedback for correct answer</span>) : (<span data-testid="feedback_wrongans" className="ml-2">Feedback for wrong answer</span>) :
                    (<><span id={"registerTip0"} className="ml-2 "><span data-testid="earned_points" className="message-text">You’ve earned {props.points.earned_points} out of {props.points.total_points} points </span><span></span>
                    </span>
                        <div className="d-none" data-testid="earned_points_tooltip">
                        <span>You received {props.points.attempting_points} point(s) for
                            </span><br />
                            <span>attempting and {props.points.accuracy_points} point(s) </span><br />
                            <span> for accuracy.</span>
                        </div>
                      <OverlayTrigger placement="top" overlay={
                        <Tooltip className="module_tooltip"
                            id={"registerTip0"}>
                            <span>You received {props.points.attempting_points} point(s) for
                            </span><br />
                            <span>attempting and {props.points.accuracy_points} point(s) </span><br />
                            <span> for accuracy.</span>
                        </Tooltip>} offset={[0, 10]}>
                            <span className="cursor-pointer"> ⓘ</span>
                        </OverlayTrigger></>)}</>}
                {/* { (<span className="ml-2">You’ve earned {props.points.earned_points} out of {props.points.total_points} points ⓘ</span>)} */}
                {props.points.length != 0  && <hr /> }
                {contentArr.length > 0 && contentArr.map((item, index) => {
                    return (
                        <Fragment key={index}>
                            {item.type == "p" &&(currentWindowWidth<769?
                            //mobile
                            <>
                            <div className="d-flex gap-2 mt-4 mb-3 message-container-gap">
                                {item.children[0].text !== '' &&
                                    (<> <div className=""><img  width="18" className="message-img-fluid" src={index == 0 ? (msg == 'yes' ? "/images/right.png" : "/images/wrong.png") : ""} /></div>
                                        <div className="message-text">{item.children[0].text?.replace('\u200B', '').replace(/[\xA0\u200B\u202F\x20]/g, " ")}</div></>)}
                            </div>
                            </>
                            // web
                            :<div className="row mt-4 mb-3">
                                {item.children[0].text !== '' &&
                                    (<> <div className="col-lg-1 col-2 col-md-1"><img data-testid="rightorwrongimage" className="img-fluid" src={index == 0 ? (msg == 'yes' ? "/images/right.png" : "/images/wrong.png") : ""} /></div>
                                        <div className="col-lg-11 col-10 col-md-11 mt-1 message-text" data-testid="message_content">{item.children[0].text?.replace('\u200B', '').replace(/[\xA0\u200B\u202F\x20]/g, " ")}</div></>)}
                            </div>)}

                            {item.type == 'img' && (<><div>
                                <img data-testid="feedback_res_image" src={item.src} className="feedbackImage" alt="image not found" />
                            </div></>)}
                            {item.type == "ul" && <>
                                <div data-testid="feedback_res_ul">
                                    <ul className={answerImgISAvailable ? 'feedbackULOL' : ''}>
                                        {item.children.map((list, index) => (
                                            <Fragment key={index}>
                                                <li>
                                                    {list.children.map((lastChild, childIndex) => (
                                                        <Fragment key={lastChild.text + childIndex}>
                                                              {lastChild.children?
                                                                <>
                                                                    {lastChild.children.map((innerlastchild,innerlastchildidx)=>(
                                                                        <Fragment key={innerlastchildidx.text+innerlastchildidx}>
                                                                            {innerlastchild.strong && <strong>{innerlastchild.text?.replace('\u200B', '').replace(/[\xA0\u200B\u202F\x20]/g, " ")}</strong>}
                                                                {!innerlastchild.strong && <span>{innerlastchild.text?.replace('\u200B', '').replace(/[\xA0\u200B\u202F\x20]/g, " ")}</span>}
                                                                        </Fragment>
                                                                    ))}
                                                                </>
                                                                  :
                                                                 <> 
                                                                    {lastChild.strong && <strong>{lastChild.text?.replace('\u200B', '').replace(/[\xA0\u200B\u202F\x20]/g, " ")}</strong>}
                                                            {!lastChild.strong && <span>{lastChild.text?.replace('\u200B', '').replace(/[\xA0\u200B\u202F\x20]/g, " ")}</span>}
                                                        </>
                                                                    }
                                                        </Fragment>
                                                    ))}
                                                </li>
                                            </Fragment>
                                        ))}
                                    </ul>
                                </div>
                            </>
                            }
                            {item.type == "ol" &&
                                <div data-testid="feedback_res_ol">
                                    <ol className={answerImgISAvailable ? 'feedbackULOL' : ''}>
                                        {item.children.map((list, index) => (
                                            <Fragment key={index}>
                                                <li>
                                                    {list.children.map((lastChild, childIndex) => (
                                                      <Fragment key={lastChild.text + childIndex}>
                                                        {lastChild.children?
                                                        <>
                                                            {lastChild.children.map((innerlastchild,innerlastchildidx)=>(
                                                                <Fragment key={innerlastchildidx.text+innerlastchildidx}>
                                                                      {innerlastchild.strong && <strong>{innerlastchild.text?.replace('\u200B', '').replace(/[\xA0\u200B\u202F\x20]/g, " ")}</strong>}
                                                        {!innerlastchild.strong && <span>{innerlastchild.text?.replace('\u200B', '').replace(/[\xA0\u200B\u202F\x20]/g, " ")}</span>}
                                                                </Fragment>
                                                            ))}
                                                        </>
                                                        :
                                                        <> 
                                                        {lastChild.strong && <strong>{lastChild.text?.replace('\u200B', '').replace(/[\xA0\u200B\u202F\x20]/g, " ")}</strong>}
                                                        {!lastChild.strong && <span>{lastChild.text?.replace('\u200B', '').replace(/[\xA0\u200B\u202F\x20]/g, " ")}</span>}
                                                        </>
                                                        }
                                                        </Fragment>
                                                    ))}
                                                </li>
                                            </Fragment>
                                        ))}
                                    </ol>
                                </div>}
                        </Fragment>
                    )
                })}

                {(contentArr.length == 0 && suceess_msg !== '') &&
                    (
                        <>
                            <div className="row mt-4 mb-3">
                                <div className="col-lg-1 col-2 col-md-1"><img className="img-fluid my_feedback_image" src={msg == 'yes' ? "/images/right.png" : "/images/wrong.png"} /></div>
                                <div className="col-lg-11 col-10 col-md-11 mt-1">{suceess_msg}</div>
                            </div>
                        </>
                    )}
            </div>
        </>
    )
}