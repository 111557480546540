import { React, useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ObjectiveComponent } from "./objectivesComponent";
import "../../homePageStyle.css";
import { ContentPage } from "./contentpage";
import axios from "../../axios-interceptor";
import { base_url, role_obj,formatSentence, setUrl, storefront_url,default_dial_code,api_error_message,random_number, user_maintenance_type, storefront_secret_key } from "../../utilities";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import withMaintenanceCheck from "../../HigherOrderComponents/maintenancePageChecker";
import { useParams } from "react-router-dom";
import CryptoJS from 'crypto-js';

 const FreeLessonPage = (props) => {
  const history = useHistory();
  const { program_id,learningtrack_id,course_id ,learningtrack, course,utmtags } = useParams();
  let [gobacklink, setGoBackLink] = useState("");
  let [track_name,setTrackName] = useState('')
 let  is_course = false
  if(course){
    let final = course.split('_')
    if(final.length >1){
      final.map(item=>{
        if(item == 'crs'){
             is_course = true
        }
      })
      if(is_course){
        localStorage.setItem('course',course.slice(0,-4))
      }else{
        localStorage.setItem('course',course)
      }
    }
    
  }
  let href_url = window.location.href
  let url_arr = href_url?.substring(href_url?.indexOf('url='))

  let params_arr = href_url.includes('params=') 
  ? href_url?.substring(href_url?.indexOf('params=') + 'params='.length) 
  : null; 
  
  const decodedURI = params_arr ? decodeURIComponent(params_arr) : null;
  const secretKey = storefront_secret_key;
  
  const decryptParams = (decodedURI) => {
    try {
      if (decodedURI) {   
        const bytes = CryptoJS.AES.decrypt(decodedURI, secretKey);
        const decryptedString = bytes.toString(CryptoJS.enc.Utf8);
        
        if (decryptedString) {
          const decryptedData = JSON.parse(decryptedString);
          return decryptedData;
        } else {
          console.warn('Decrypted string is empty or invalid.');
          return null;
        }
      } else {
    console.warn('No params provided for decryption.');
    return null;
  }
} catch (error) {
  console.error('Error decrypting data:', error.message);
  return null;  
}
};

const decryptedData = decryptParams(decodedURI);

const containsParamValues = decryptedData && Object.values(decryptedData).every(
  (value) => value !== undefined && value !== 'undefined' && value !== null && value !== ''
);
//////////Params code added//////////////////

if(url_arr.includes('url=')){
  let modified_url = url_arr.replace('@!@!', '//')
  let final_url =  modified_url.replace('@!', '/');
      let arr =final_url.split('&') 
      arr.shift(); // Remove the first element
      let utmtags = arr.join('&');
      localStorage.setItem('url',final_url.substring(final_url.indexOf('https')) )
      localStorage.setItem('utmtags', utmtags)   
  }
  const location = useLocation();
  const pageId = 0;
  let [progress_element, setProgressElement] = useState(0);
  let [total_length, setTotalLength] = useState(0);
  const [model_array, setPage] = useState([]);
  const [lessons_data, setLessonData] = useState([]);
  const [next_id, setNextId] = useState(0);
  const [next_lesson_id, setNextLessonId] = useState("");
  const [next_module_id, setNextModuleId] = useState(0);
  // const [lesson_id, setLessonId] = useState(lessonId);
  // const [module_Id, setModuleId] = useState(moduleId);
  const [complete_lesson, setCompleteLesson] = useState(false);
  const [course_name, setCourseName] = useState("");
  const [module_name, setModuleName] = useState("");
  const [lesson_name, setLessonName] = useState("");
  var [total_score, setTotalScrore] = useState();
  var [show_next, setShowNext] = useState("");
  var data = [];
  var module_data = [];
  var [idArr, setIdArr] = useState([]);
  var name = "";
  let [role,setRole] = useState()
  let [awsSessionData, setAws] = useState(JSON.parse(sessionStorage.getItem("s3AwsVideoLinks")));
  const hasFetchedData = useRef(false); 
  const [loader,setLoader]=useState(false);

  useEffect(()=>{
    setLoader(true);
    const fetchAwsAndData = async () => {
      await getAws();
      const sessionData = JSON.parse(sessionStorage.getItem("s3AwsVideoLinks"));
      setAws(sessionData);
    };
  
    fetchAwsAndData();
  },[]);
  
  const getAws=async()=>{
  await axios.get(base_url+"isbx/get_aws_video_links")
  .then((res)=>{
    sessionStorage.setItem("s3AwsVideoLinks",JSON.stringify(res.data?.data))

  })
  .catch((err)=>{
    console.log("error",err)
  })
  }

  useEffect(() => { 
    if (awsSessionData && !hasFetchedData.current) {
      dataUseEffect();
      hasFetchedData.current = true;
    }
  }, [awsSessionData])

 const dataUseEffect=() => {
    document.getElementById("top-nav-bar").style.display = "none";
    const cookies = document.cookie.split(';')
    .map(cookie => cookie.trim())
    .reduce((acc, cookie) => {
      const [key, value] = cookie.split('=');
      acc[key] = decodeURIComponent(value);
      return acc;
    }, {});
    const {leadform_name,leadform_email,leadform_mobile,leadform_ProgramId,leadform_role,leadform_country_code,leadform_id}=cookies
    setRole((decryptedData?.role) ? (decryptedData?.role) :  leadform_role)
    if(course_id){
      localStorage.setItem('free_course_id',course_id)
    }
    else{
      navToInvalidPage()
    }
    if(learningtrack){
      localStorage.setItem('learningtrack',learningtrack)
      setTrackName(formatSentence(learningtrack)) 
    }
    setUrl();  
    setLoader(false);
    localStorage.setItem('free_lesson_url',true);
    if(localStorage.getItem('user_id') && localStorage.getItem('isLoggedIn') == 'true'){
      if( !leadform_name||!leadform_email||!leadform_mobile||!leadform_ProgramId||!leadform_role ||!leadform_country_code || leadform_ProgramId!=localStorage.getItem('programId')){
        axios
          .post(base_url + "user/my_courses", {
            user_id: localStorage.getItem('user_id')
          })
          .then((res) => {
            let result = res.data.data;
            let SaveObj = {
              "name": result.first_name || decryptedData?.name,
              "first_name": result.first_name || decryptedData?.name,
              "last_name": result.first_name || decryptedData?.name,
              "email": result.email || decryptedData?.email,
              "mobile": result.mobile || decryptedData?.mobile,
              "learning_track_id":localStorage.getItem('learningTrackId'),
              "course_id": localStorage.getItem('free_course_id'),
              "program_id": program_id,
              "tags": localStorage.getItem('utmtags')? localStorage.getItem('utmtags') :"",
              "url": localStorage.getItem('url')? localStorage.getItem('url'):"",
              "is_free_lesson": localStorage.getItem('free_lesson_url')? true: false,
              "is_terms_accepted":true,
              "role":role_obj.my_self || decryptedData?.role,
              "country_code":(result.country_code) ? (result.country_code) : (decryptedData?.countryCode) ? (decryptedData?.countryCode) : default_dial_code
          }
          axios.post(base_url+'free_lesson/user_create',SaveObj).then(res=>{
              if(res.data.status == 200){
                const now = new Date();
                const expirationDate = new Date(now.getTime() + 365 * 24 * 60 * 60 * 1000); // one year from now
                document.cookie = `leadform_name=${result.first_name.trim()}; expires=${expirationDate.toUTCString()}; path=/;`;
                document.cookie = `leadform_email=${result.email?.trim()}; expires=${expirationDate.toUTCString()}; path=/;`;
                document.cookie = `leadform_mobile=${ result.mobile}; expires=${expirationDate.toUTCString()}; path=/;`;
                document.cookie = `leadform_country_code=${result.country_code ? result.country_code : default_dial_code}; expires=${expirationDate.toUTCString()}; path=/;`;
                document.cookie = `leadform_ProgramId=${program_id}; expires=${expirationDate.toUTCString()}; path=/;`;
                document.cookie = `leadform_role=${role_obj.my_self}; expires=${expirationDate.toUTCString()}; path=/;`;
                document.cookie = `leadform_id=${res.data.user_id}; expires=${expirationDate.toUTCString()}; path=/;`;
                freeLessonLogin();
              }
            }).catch(err=>{
              console.log(err)
              if(err.response?.statusText=='Unauthorized'){
                localStorage.clear();
                history.push('/')
                window.location.reload();
              }
              else if(err.response?.statusText== "Bad Request"){
                navToInvalidPage()
              }
              else{
                toast.warn(api_error_message,{toastId:random_number});
              }
          })
          })
      }else{
        freeLessonLogin();
      }
    }else{
      if(!localStorage.getItem('programId') || (program_id != localStorage.getItem('programId'))){
        
        localStorage.setItem('learningTrackId',learningtrack_id)
        localStorage.setItem('programId',program_id)
        if( !leadform_name||!leadform_email||!leadform_mobile||!leadform_ProgramId|| !leadform_role || !leadform_country_code|| leadform_ProgramId!=localStorage.getItem('programId'))
        {
          history.push('/freelessonlogin')
        }
        // if(!localStorage.getItem('isLoggedIn'))
        // {
        //   history.push('/freelessonlogin')
        // }
        else{
          let SaveObj = {
            "name": (decryptedData?.name)?( decryptedData?.name ): leadform_name,
            "first_name":(decryptedData?.name) ? (decryptedData?.name)  : leadform_name,
            "last_name":(decryptedData?.name) ? (decryptedData?.name) : leadform_name,
            "email": (decryptedData?.email) ? (decryptedData?.email) : leadform_email,
            "mobile": (decryptedData?.mobile) ? (decryptedData?.mobile ): leadform_mobile,
            "learning_track_id":localStorage.getItem('learningTrackId'),
            "course_id": localStorage.getItem('free_course_id'),
            "program_id": localStorage.getItem('programId'),
            "tags": localStorage.getItem('utmtags')? localStorage.getItem('utmtags') :"",
            "url": localStorage.getItem('url')? localStorage.getItem('url'):"",
            "is_free_lesson": localStorage.getItem('free_lesson_url')? true: false,
            "is_terms_accepted":true,
            "role":(decryptedData?.role) ? (decryptedData?.role) : data.role
        }
        axios.post(base_url+'free_lesson/user_create',SaveObj).then(res=>{
            if(res.data.status == 200){
              const now = new Date();
              const expirationDate = new Date(now.getTime() + 365 * 24 * 60 * 60 * 1000); // one year from now
              document.cookie = `leadform_id=${res.data.user_id}; expires=${expirationDate.toUTCString()}; path=/;`;
              const {access_token,refresh_token}=res.data
              localStorage.setItem('User_Accesstoken',access_token)
              localStorage.setItem('User_Refreshtoken',refresh_token)
              let url = localStorage.getItem('prev_url')
              history.push(url.split(window.location.host)[1])
              window.location.reload();
            }
        }).catch(err=>{
            console.log(err)
            if(err.response?.statusText=='Unauthorized'){
              localStorage.clear();
              history.push('/')
              window.location.reload();
            }
            else if(err.response?.statusText== "Bad Request"){
              navToInvalidPage()
            }
            else{
              toast.warn(api_error_message,{toastId:random_number});
            }
            // handleDisbleLoaderandSaveBtn(false)
        })
        
        }
      }else{
        // if(!localStorage.getItem('User_Accesstoken'))
        // {
        //   history.push('/freelessonlogin')
        // }
        if(!containsParamValues && (!leadform_id || !leadform_name||!leadform_email||!leadform_mobile||!leadform_ProgramId||!leadform_role ||!leadform_country_code || leadform_ProgramId!=localStorage.getItem('programId')))
        {
          history.push('/freelessonlogin');
        }else{
          freeLessonLogin();
        }
      }
    }
}

  const handleCallback = (data) => {
    setShowNext(data);
  };

  const recursive_children=(value)=>{
    let data=value.children
    for(let j=0;j<data.length;j++){
      if(data[j].type==="video" && data[j].src && data[j].src.length && data[j].src[0].url){
        let url=data[j].src[0].url
        if (url?.includes("panopto")) {
          awsSessionData?.forEach((item, index) => {
            if (item && item.Panopto === url) {
                data[j].src[0]["s3url"]=item.MP4.trim();
                data[j].src[0]["SRT"]=item.SRT;
                data[j].src[0]["TN"]=item.TN;
                data[j].src[0]["play_panopto"]=item.Panopto;
            }
            }
          )
      }
      else{
          awsSessionData?.forEach((item, index) => {
          if (item && item.MP4.trim() === url.trim()) {
              data[j].src[0]["s3url"]=item.MP4.trim();
              data[j].src[0]["SRT"]=item.SRT;
              data[j].src[0]["TN"]=item.TN;
              data[j].src[0]["play_panopto"]=item.Panopto;
              
          }
          else if (item && item.Panopto.trim() === url.trim()) {
            data[j].src[0]["s3url"]=item.Panopto.trim();
            data[j].src[0]["SRT"]=item.SRT;
            data[j].src[0]["TN"]=item.TN;
            data[j].src[0]["play_panopto"]=item.Panopto;
            
        }
          })
      }
    }   
  }
}

  const recursive_video=(data)=>{
    let data_children=data.children
    for(var i=0;i<data_children.length;i++){
      recursive_children(data.children[i])
    }
  }

  const freeLessonLogin = ()=>{
    localStorage.setItem('learningTrackId',learningtrack_id)
    localStorage.setItem('programId',program_id)
    if(learningtrack && course){
      localStorage.setItem('learningtrack',learningtrack)
      if(course){
        let final = course.split('_')
        if(final.length >1){
          final.map(item=>{
            if(item == 'crs'){
                is_course = true
            }
          })
          if(is_course){
            localStorage.setItem('course',course.slice(0,-4))
            setGoBackLink(`${storefront_url}${learningtrack}/${course}`)
          }else{
            if(course.includes('url=')){
              setGoBackLink(`${storefront_url}${learningtrack}`);
            }
          }
        }
        
      }
      
    }else if(learningtrack){
      localStorage.setItem('learningtrack',learningtrack)
      setGoBackLink(`${storefront_url}${learningtrack}`);
    }else{
      setGoBackLink(storefront_url);
    }
    axios
    .post(base_url + "create/get_learning_track", {
      learning_track_id: localStorage.getItem('learningTrackId')
    })
    .then((res) => {
    axios
      .post(base_url + "lessons/get_free_lesson_details/course", {
        course_id: localStorage.getItem('free_course_id'),
      })
      .then((res) => {
        if(res.data.message)
        {
          // "There is no free lesson for this course"
          navToInvalidPage()
        }
        else{
          localStorage.removeItem('free_lesson_url')
          localStorage.removeItem('prev_url')
          let data_aws=res.data.result.content.model;
          for(var i=0;i<data_aws.length;i++){
            if(data_aws[i].purpose==="none"){
                recursive_video(data_aws[i]);
            }
          }
          setLessonName(res.data.result.title);
          setPage(res.data.result.content.model);
          if (
            res.data.result.content.model[0].children[
              res.data.result.content.model[0].children?.length - 1
            ]?.activity_id
          ) {
            setShowNext("");
          } else {
            setShowNext("false");
          }
          setLessonData((current) => [
            ...current,
            <>
              <ContentPage
                parentCallback={handleCallback}
                data={res.data.result.content.model[0]}
              ></ContentPage>
              <p className="mb-0">
                <strong className="container myContainerCls bg-transparent p-0 mt-1 d-flex flex-row justify-content-end fw-bolder opacity-50 align-self-end">
                  Section {progress_element + 1} of{" "}
                  {res.data.result.content.model.length}
                </strong>{" "}
              </p>
            </>,
          ]);
        }
      })
      .catch((err) => {
        if(err.response?.statusText=='Unauthorized'){
          history.push('/freelessonlogin')
        }
        console.log(err);
        if(err.response?.statusText=='Unauthorized'){
          localStorage.clear();
          history.push('/')
          window.location.reload();
        }else if(err.response?.statusText== "Bad Request"){
          navToInvalidPage()
        }
        else{
          toast.warn(api_error_message,{toastId:random_number});
        }
      });
    }).catch(err=>{
      if(err.response?.statusText=='Unauthorized'){
        localStorage.clear();
        history.push('/')
        window.location.reload();
      }
      else if(err.response?.statusText== "Bad Request"){
        navToInvalidPage()
      }
      else{
         toast.warn(api_error_message,{toastId:random_number});     
      }
    })
  }
  const navToInvalidPage=()=>
  {
    history.push("/invalidfreelessonlink");
  }
  const pauseVideoRoll = (id) => {
    var iframes = document.getElementsByClassName("panaptoVideo");
    if (iframes.length !== null) {
      Array.from(iframes).map((item) => {
        var iframeSrc = item.src;
        item.src = iframeSrc;
      });
    }
  };

  const showNextElement = (item) => {
    var activity_id = null;
    var activity_entity_type = null;
    pauseVideoRoll();
    if (model_array[item + 1]) {
      if (
        model_array[item + 1].children[
          model_array[item + 1].children?.length - 1
        ]?.activity_id
      ) {
        setShowNext("");
      } else {
        setShowNext("false");
      }
    }

    if (
      model_array[item].children[model_array[item].children?.length - 1]
        ?.activity_id
    ) {
      activity_id =
        model_array[item].children[model_array[item].children.length - 1]
          .children[0].activity_id;
      activity_entity_type =
        model_array[item].children[model_array[item].children.length - 1]
          .children[0].acitivity_entity_type;
    }

    setProgressElement(item + 1);
    if (progress_element < model_array.length - 1) {
      setLessonData((current) => [
        ...current,
        <>
          <ContentPage
            parentCallback={handleCallback}
            data={model_array[item + 1]}
          ></ContentPage>
          <p className="mb-0">
            {" "}
            <strong className="container myContainerCls bg-transparent p-0 mt-1 d-flex flex-row justify-content-end fw-bolder opacity-50 align-self-end">
              Section {progress_element + 2} of {model_array.length}
            </strong>
          </p>
        </>,
      ]);
    } else {
      setCompleteLesson(true);
      showNextElementScroll()
      setProgressElement(0);
    }
  };
  const showNextElementScroll=()=>{
     setTimeout(() => {
      window.scrollTo({
        top: window.scrollY+window.innerHeight,
        left: 0,
        behavior: "smooth",
      });
    }, 300);
  }
  const setSubmitFlag = () => {
    return localStorage.getItem("submitted");
  };
 
  const goToOnlineAppllicationPage = () => {
    
    localStorage.setItem("AppCourseId", localStorage.getItem('free_course_id'));
    localStorage.removeItem("AppCohortId");
    if(localStorage.getItem('free_lesson_user_id'))
    {
      axios.post(base_url+'free_lesson/update_free_lesson_status',{
        "free_lesson_user_id": localStorage.getItem('free_lesson_user_id'),
        "is_free_lesson_completed":1
      }).then(res=>{
        if(res.data.status == 200){
          history.push('/onlineapplication')
          window.location.reload()
          // history.push(`/socialloginscreen/${localStorage.getItem('learningTrackId')}`); //,{cohort:JSON.parse(localStorage.getItem('cohortData'))}
        }
        // console.log(res)
      }).catch(err=>{
        console.log(err);
        if(err.response?.statusText=='Unauthorized'){
          localStorage.clear();
          history.push('/')
          window.location.reload();
        }else{
          toast.warn(api_error_message,{toastId:random_number});
        }
      })
    }
    else{
      history.push('/onlineapplication')
      window.location.reload()
    }

    // localStorage.setItem('learningTrackId',learningtrack)
  };
const goBackToStoreFront = () =>{
  if(localStorage.getItem('free_lesson_user_id'))
  {
    axios.post(base_url+'free_lesson/update_free_lesson_status',{
      "free_lesson_user_id": localStorage.getItem('free_lesson_user_id'),
      "is_free_lesson_completed":1
    }).then(res=>{
      if(res.data.status == 200){
      localStorage.clear();
      window.location.href=(gobacklink)
      }
    }).catch(err=>{
      console.log(err);
      if(err.response?.statusText=='Unauthorized'){
        localStorage.clear();
        history.push('/')
        window.location.reload();
      }else{
        toast.warn(api_error_message,{toastId:random_number});
      }
    })
  }
  else{
    localStorage.clear();
    window.location.href=(gobacklink)
  }

  
}
const goToStoreFrontExpolre = () =>{
  window.location.href= `${storefront_url}lxp-page`
}
  const handle = useFullScreenHandle();
 
    return (
      <>
      {loader ?
        <img className="atrium_loader" src="/images/atrium_loader.gif" alt="Loader" />:""
      }
       {
        program_id == localStorage.getItem('programId') && 
        (
          <div className="lessonpage">
          <div className="">
            {/* className="border-class bg-transparent" */}
            {/* <ObjectiveComponent data={objectives}></ObjectiveComponent> */}
            {/* {lessons_data.length != 0 && (
            <div
              className="container  myContainerCls bg-transparent p-0 d-flex flex-row h6 fw-normal align-middle"
              style={{ color: "#708FAB", marginTop: "50px" }}
            >
              {course_name} &nbsp; &gt; {module_name} &gt; {lesson_name}
            </div>
          )} */}
            {lessons_data}

            {complete_lesson && (
              <div className="container myContainerCls mt-5">
                <div>
                <div
              className="my_alum_container p-5 col-12 custm_shadow_cls bg-body"
            >
              <div className="col-12">
                <div className="d-flex flex-column">
                <div className="alum_msg_container">
                <div className="row">
                  <div className="my_alum_main_head">
                  {role == role_obj.my_self?  <span className="alum-head">Become an ISB Online Alum!</span>:
                   <span className="alum-head">Join the ISB Online Community</span>}
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-3 col-md-4 col-sm-12 ml-3 ml-sm-0">
                    <div>
                      <span className="img-fluid d-block mx-auto alum_image"></span>
                    </div>
                  </div>
                  <div className="col-lg-9 col-sm-12 col-md-8">
                    <div className="alum_text_top">
                      <span className="alum_text">
                      This lesson was a short preview of the comprehensive {track_name} learning track.
                      </span>
                    </div>
                    <div>
                    {role == role_obj.my_self?  <span className="alum_text"><br/>
                      Join thousands of working professionals, students and lifelong learners in gaining valuable management skills in the next generation learning platform at ISB Online.
                      </span> :<span className="alum_text"><br/>
Empower your team with cutting-edge business education and drive success. Explore our platform further to know more about our learning experience.
                      </span>}
                    </div>
                    <div className="text-center mt-4">
                      {/* <button className="btn  my_outline_button_cls align-self-end mr-4 mt-0 alum_course" onClick={goBackToStoreFront}>
                      View Course Details 
                      </button> */}
                      {role == role_obj.my_self?  <button className="btn mt-0 float-end alum_apply" onClick={goToOnlineAppllicationPage}>
                      Apply Now 
                      </button>: <button className="btn mt-0 float-end alum_apply" onClick={goToStoreFrontExpolre}>
                      Explore
                      </button> }
                    </div>
                  </div>
                </div>
                </div>
                  </div>
                  </div>
                </div>
                  </div> 
              </div>
             
            )}
          </div>
          <br></br>
          {!complete_lesson && (
            <div className="navigation d-flex justify-content-center">
              {lessons_data.length != 0 && (
                <>
                  <button
                    className="btn btn-lg my_btn_cls shadow border border-light border-4 rounded-circle"
                    onClick={() => showNextElement(progress_element)}
                    disabled={show_next == "" ? true : false}
                  >
                    <img
                      className="bg-transparent"
                      src="/images/downarrow.png"
                      alt=""
                    />
                  </button>
                </>
              )}
            </div>
          )}
        </div>
        )
      }
      </>
    );
  
};
export default withMaintenanceCheck(FreeLessonPage,user_maintenance_type) 